<template>
  <div>
    <p>Registration Error</p>
    <div v-if="this.errors && this.errors.userAlreadyExists">
    Problem creating user (userAlreadyExists). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.insertUserFailed">
    Problem creating user (insertUserFailed). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.insertCodeFailed">
    Problem creating user (insertCodeFailed). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.sendEmailFailed">
    Problem creating user (sendEmailFailed). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.codeNotFound">
    Invalid code (codeNotFound). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.updateUserFailed">
    Problem updating user (updateUserFailed). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.selectUserFailed">
    Problem selecting user (selectUserFailed). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.deleteCodeFailed">
    Problem deleting code (deleteCodeFailed). Please contact support for assistance.
    </div>

    <div v-if="this.errors && this.errors.codeInvalid">
    Invalid code. Please contact support for assistance.
    </div>
  </div>
</template>

<script>

export default {
  name: 'RegistrationError',
  computed: {
      errors() {
          return this.$route.params;
      }
  }
}
</script>
