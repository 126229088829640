import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        welcome: "Hello, there."
    },
    '简': {
        welcome: "Ni hao."
    },
    '繁': {
        welcome: "Hiya hiya"
    }
}

const i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'en',
    messages,
});

export default i18n;
