<template>
  <div>
      {{ error }}
    <form @submit="submitForm">
        <div>
            <input v-model="form.fullName" type="text" placeholder="Name On Credit Card" />
            <span v-if="formErrors.fullNameEmpty">Name is required.</span>
            <span v-if="formErrors.fullNameTooLong">Name must be shorter than 255 characters.</span>
        </div>
        <div>
            <input v-model="form.email" type="text" placeholder="E-mail Address (For Invoices)" />
            <span v-if="formErrors.emailEmpty">E-mail is required.</span>
            <span v-if="formErrors.emailInvalid">Invalid e-mail address.</span>
            <span v-if="formErrors.emailTooLong">E-mail must be shorter than 50 characters.</span>
        </div>
        <div id="card-element" class="card"></div>
        <div>
            <button :disabled="sending">Submit</button>
        </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'CardForm',
  data() {
        return {
            form: {
                fullName: '',
                email: this.client.email
            },
            formErrors: {
                fullNameEmpty: false,
                fullNameTooLong: false,
                emailEmpty: false,
                emailInvalid: false,
                emailTooLong: false,
            },
            stripe: '',
            cardElement: '',
            error: null,
            sending: false
        }
  },
  props: ['client'],
  mounted() {
    this.stripe = window.Stripe(this.client.key);

    const elements = this.stripe.elements();
    this.cardElement = elements.create('card');
    this.cardElement.mount('#card-element');
  },
  methods: {
      async submitForm(evt) {

          evt.preventDefault();

          this.sending = true;

          this.clearErrors();

          this.formErrors = this.validateForm(this.form);

          // if formErrors is not empty
          if (Object.keys(this.formErrors).length) return;

          // everything ok
          // create payment method and confirm payment intent
          try {
              const result = await this.stripe.confirmCardPayment(this.client.secret,
                  {
                      payment_method: {
                          card: this.cardElement,
                          billing_details: {
                              name: this.form.fullName,
                          },
                      },
                      receipt_email: this.form.email
                  }
              );
              return this.$emit('result', result);
          } catch (error) {
              // error might be in result.error
              this.error = error.message;
          }
      },
      clearErrors() {

          this.error = null;
          this.formErrors.fullNameEmpty = false;
          this.formErrors.fullNameTooLong = false;
          this.formErrors.emailEmpty = false;
          this.formErrors.emailInvalid = false;
          this.formErrors.emailTooLong = false;

      },
      validateForm(form) {

          // check the entire form in one go rather than updating formErrors one by one
          const errors = {};

          if (!form.fullName)
            errors.fullNameEmpty = true;

          if (form.fullName.length > 255)
            errors.fullNameTooLong = true;

          if (!form.email)
            errors.emailEmpty = true;

          if (form.email.length > 50)
            errors.emailTooLong = true;

          if (form.email && !(/^\S+@\S+\.\S+$/.test(form.email)))
            errors.emailInvalid = true;

          return errors;
      }
  },
}
</script>

<style scoped>


</style>
