<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <Modal v-if="websites"
        :del="del"
        :dis="dis"
        :deleteModalOpen="deleteModalOpen"
        :disableModalOpen="disableModalOpen"
        :deletedWebsite="deletedWebsite"
        :disabledWebsite="disabledWebsite"
        :websites="websites"
        :updateSidebar="updateSidebar"
        @deleteModalOpen="changeDeleteModalOpen"
        @disableModalOpen="changeDisableModalOpen"
        @websites="changeWebsites"
        @updateSidebar="changeUpdateSidebar" />
      <div class="dashboard dashboard-keywords">
        <Sidebar :reports="false" :addWeb="true" :webButtonActive="true" :updateSidebar="updateSidebar" :reload="true" />
        <div v-show="ready" class="dash-content">
          <div class="dash-inner-content">
            <div class="web-list-block">
              <h1>Websites</h1>
              <router-link :to="{ name: 'AddWebsite'}" class="add-site-btn"><img src="/img/dashboard/add_active.svg" alt="">Add Site</router-link>
              <div class="overflow-x-auto">
                <div v-if="websites && websites.length" class="web-table">
                  <div class="heading">
                    <div class="label-site">Site</div>
                    <div class="label-status">Status</div>
                    <div class="label-disable">Enabled</div>
                    <div class="label-disable">Block Fake Leads</div>
                    <div class="label-delete">Delete</div>
                    <div class="label-settings">Settings</div>
                  </div>
                  <div v-for="(website, index) in websites" :key="website.domain" class="table-body">
                    <div class="b-site">{{ website.domain }}</div>
                    <div v-if="!website.traffic" class="b-status status-pending"><span>Waiting for traffic</span></div>
                    <div v-if="website.traffic && website.active" class="b-status status-active"><span>Active</span></div>
                    <div v-if="website.traffic && !website.active" class="b-status status-disabled"><span>Disabled</span></div>
                    <div v-if="!website.traffic" class="b-disable">
                      <input class="checkbox" id="checkbox3" type="checkbox" disabled>
                      <label class="toggle" for="checkbox3">
                        <span class="disc"></span>
                      </label>
                    </div>
                    <div v-if="website.traffic && website.active" class="b-disable">
                        <input class="checkbox" :id="'checkbox'+index" type="checkbox" v-model="website.active" @change="handleDisableClick(website.domain, index)">
                        <label class="toggle toggle-approved-site" :for="'checkbox'+index">
                          <span class="disc disc-approved-site"></span>
                        </label>
                    </div>
                    <div v-if="website.traffic && !website.active" class="b-disable">
                        <input class="checkbox" :id="'checkbox'+index" type="checkbox" v-model="website.active" @change="handleDisableClick(website.domain, index)">
                        <label class="toggle toggle-approved-site" :for="'checkbox'+index">
                          <span class="disc disc-approved-site"></span>
                        </label>
                    </div>
                    <div v-if="website.leads" class="b-disable">
                        <input class="checkbox" :id="'checkbox2'+index" type="checkbox" v-model="website.leads" @change="handleDisableBlock(website.domain, index)">
                        <label class="toggle toggle-approved-site" :for="'checkbox2'+index">
                          <span class="disc disc-approved-site"></span>
                        </label>
                    </div>
                    <div v-if="!website.leads" class="b-disable">
                      <input class="checkbox" :id="'checkbox2'+index" type="checkbox" v-model="website.leads" @change="handleDisableBlock(website.domain, index)">
                      <label class="toggle toggle-approved-site" :for="'checkbox2'+index">
                        <span class="disc disc-approved-site"></span>
                      </label>
                    </div>
                    <div class="b-delete" @click="handleDeleteClick(website.domain)"><img src="/img/dashboard/delete.svg" alt="Delete" class="hover"></div>
                    <div class="b-settings"><router-link :to="{ name: 'WebsiteSettings', params: { website: website.domain }}"><img src="/img/dashboard/settings_icon_menu_light.svg" alt="Settings"></router-link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Modal from '@/components/dashboard/Modal.vue'
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Dashboard',
    data() {
        return {
            error: '',
            ready: false,
            websites: null,
            clicks: null,
            del: null,
            dis: null,
            deleteModalOpen: false,
            disableModalOpen: false,
            disabledWebsite: null,
            deletedWebsite: null,
            updateSidebar: 0
        }
    },
    components: {
        Header,
        Modal,
        Sidebar
    },
    methods: {
        handleDisableBlock: async function(domain, index) {
            try {
                await this.$http.get('/dashboard/website/leads/' + domain + '/toggle');
            } catch(error) {
                this.error = error;
                if (this.websites[index].leads == 1)
                  this.websites[index].leads = 0;
                else
                  this.websites[index].leads = 1;
            }
        },
        handleDeleteClick: function(websiteName) {
          this.deleteModalOpen = true;
          this.deletedWebsite = websiteName;
          this.del = null;
        },
        handleDisableClick: async function(websiteName, index) {
            this.dis = null;
            this.disabledWebsite = websiteName;
            let website = this.websites.find(w => w.domain == websiteName);
            if (website.active == 0) {
                this.disableModalOpen = true;
                if (this.websites[index].leads == 1) {
                  this.handleDisableBlock(websiteName, index);
                  this.websites[index].leads = 0;
                }
            } else {
                website.active = 1;
                try {
                    await this.$http.get('/dashboard/website/' + this.disabledWebsite + '/activate');
                    await this.$store.dispatch('deleteWebsites');
                    localStorage.removeItem('websites');
                    this.updateSidebar++;
                } catch(error) {
                    this.error = error;
                }
            }
        },
        changeDeleteModalOpen(evt) {
            this.deleteModalOpen = evt;
        },
        changeDisableModalOpen(evt) {
            this.disableModalOpen = evt;
        },
        changeWebsites(evt) {
            this.websites.splice(evt,1);
        },
        changeUpdateSidebar() {
            this.updateSidebar++;
        }
    },
    async created() {

        if (this.$route.params.clicks) {
            this.clicks = this.$route.params.clicks;

            // i think i can remove the 10,000, 75,000 values, etc.
            if ((this.clicks != "500") && (this.clicks != "10,000") && (this.clicks != "25,000") &&
                (this.clicks != "50,000") && (this.clicks != "75,000") && (this.clicks != "100,000") &&
                (this.clicks != "200,000") && (this.clicks != "500,000")) {
                this.clicks = null;
            }
        }

        // even if first time logging in, need to double check he doesn't have an existing subscription
        // as he may be logging in again using the account activation e-mail

        // check his subscription
        try {
            let response = await this.$http.get('/billing/subscription/status');

            this.$store.dispatch('setPaid', response.data.result.paid == true);

            if (response.data.result.expired && this.clicks) {
                // no active subscription
                if (this.clicks == "500") {
                    let url = '/billing/subscription/create';

                    try {
                        await this.$http.post(url,
                            {
                                price_id: 'price_free',
                                product_id: 'prod_free',
                            }
                        );

                    } catch(error) {
                        this.error = error.response.data.error;
                    }

                } else {
                    return this.$router.push({
                        name: 'FirstSubscription',
                        params: { clicks: this.clicks }
                    });
                }

            } else if (response.data.result.expired) {
                // we don't know their clicks
                // this is because register -> e-mail -> close browser -> login, so we lost his clicks variable
                // need to fetch it from database

                try {
                    let response = await this.$http.get('/authentication/package');
                    this.clicks = response.data.clicks.clicks;

                    return this.$router.push({
                        name: 'FirstSubscription',
                        params: { clicks: this.clicks }
                    });

                } catch(error) {
                    this.error = error;
                }
            }
        } catch(error) {
            this.error = error;
        }

        // at this point his subscription is ok
        // either ask him to add a website or show the main dashboard

        // get his websites

        try {
            let response = await this.$http.get('/dashboard/websites');

            this.websites = response.data.result;

        } catch(error) {
            this.error = error;
        }

        // if there is only one website, need to check it has traffic
        /*if (this.websites.length == 1) {

            try {
                let response = await this.$http.get('/dashboard/website/' + this.websites[0].domain + '/traffic');

                this.traffic = response.data.result;

                // no traffic, send to AddCode
                if (!this.traffic) {
                    return this.$router.push({
                        name: 'AddCode',
                        website: this.websites[0].domain
                    });
                } else {
                    this.ready = true;
                }

            } catch(error) {
                this.error = error;
            }

        } else */

        if (!this.websites.length) {
            // if no websites
            return this.$router.push({
                name: 'AddFirstWebsite'
            });
        } else {
            this.ready = true;
        }
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            console.log(content)
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

    },
}
</script>

<style>
.hover:hover{
    cursor: pointer;
}

</style>
