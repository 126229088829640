const state = {
    language: localStorage.getItem('language') || 'en',
    paid: localStorage.getItem('paid') === 'true' || false,
    keywords: null,
    websites: null
};

const mutations = {
    SET_LANGUAGE(state, language) {
        state.language = language;
    },
    SET_PAID(state, paid) {
        state.free = paid;
    },
    SET_KEYWORDS(state, keywords) {
        state.keywords = keywords;
    },
    DELETE_KEYWORDS(state) {
        state.keywords = null;
    },
    SET_WEBSITES(state, websites) {
        state.websites = websites;
    },
    DELETE_WEBSITES(state) {
        state.websites = null;
    }

};

const actions = {
    setLanguage({commit}, language) {
        commit('SET_LANGUAGE', language);
        localStorage.setItem('language', language);
    },
    setPaid({commit}, paid) {
        commit('SET_PAID', paid);
        localStorage.setItem('paid', paid);
    },
    setKeywords({commit}, keywords) {
        commit('SET_KEYWORDS', keywords);
    },
    deleteKeywords({commit}) {
        commit('DELETE_KEYWORDS');
    },
    setWebsites({commit}, websites) {
        commit('SET_WEBSITES', websites);
    },
    deleteWebsites({commit}) {
        commit('DELETE_WEBSITES');
    }
};

const getters = {
    getLanguage(state) {
        return state.language;
    },
    isPaid(state) {
        return state.paid;
    },
    getKeywords(state) {
      return state.keywords;
    },
    getWebsites(state) {
      return state.websites;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
}
