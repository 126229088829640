const state = {
    token: localStorage.getItem('token') || null
};

const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
    }
};

const actions = {

    login({commit}, payload) {

        localStorage.setItem('token', payload.token);
        commit('SET_TOKEN', payload.token);
        this._vm.$http.defaults.headers.common['Authorization'] = payload.token;

    },

    logout({commit}) {

        localStorage.removeItem('token');
        commit('SET_TOKEN', null);
        delete this._vm.$http.defaults.headers.common['Authorization'];
    }
};

const getters = {

    isAuthenticated(state) {
        return !!state.token && state.token === localStorage.getItem("token");
    },
    getToken(state) {
        return state.token;
    }

}

export default {
    state,
    mutations,
    actions,
    getters
}
