<template>
  <div></div>
</template>

<script>
export default {
    name: 'RegistrationActivate',
    props: ['code', 'clicks'],
    async created() {

        if ((!/^[a-z0-9]+$/i.test(this.code) || this.code.length !== 25)
            || ((this.clicks != "500") && (this.clicks != "10,000") && (this.clicks != "25,000") &&
                (this.clicks != "50,000") && (this.clicks != "75,000") && (this.clicks != "100,000") &&
                (this.clicks != "200,000") && (this.clicks != "500,000"))) {

            return this.$router.push({
                name: 'RegistrationError',
                params: { codeInvalid: true }
            });

        } else {

            try {
                await this.$http.post('/authentication/register/confirm',
                    {
                        code: this.code,
                        timezone: this.getTimezone()
                    }
                );

                // record the conversion in google
                // every conversion (via google ads or not) will be recorded
                this.$gtag.event('conversion', {
                  'send_to': 'AW-325180611/rCufCOzThqkDEMO5h5sB',
                  'transaction_id': ''
                });

                this.$router.push({
                    name: 'Login',
                    params: { confirmed: true, clicks: this.clicks }
                });

            } catch(error) {

                this.$router.push({
                    name: 'RegistrationError',
                    params: error.response.data.error
                });

            }
        }
    },
    methods: {
        getTimezone() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        },
    }
}
</script>
