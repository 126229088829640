<template>
  <tr>
      <td>{{ created }}</td>
      <td>{{ invoiceNumber }}</td>
      <td>{{ paid }}</td>
      <td><a :href="invoice.hosted_invoice_url" target="_new">Open</a></td>
  </tr>
</template>

<script>
export default {
  name: 'Invoice',
  props: ['invoice'],
  computed: {
      created() {
          return new Date(this.invoice.created * 1000).toISOString().substring(0,10);
      },
      invoiceNumber() {
          return this.invoice.number;
      },
      paid() {
          return this.invoice.amount_paid/100;
      }
  }
}
</script>

<style scoped>


</style>
