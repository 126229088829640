<template>
    <div class="sign-up-wrap">
      <HeaderAuth />
      <div class="sign-up centered">
        <div class="form-block">
          <div class="form-block-inner">
            <h1 class="ttl center-text">Verify E-mail</h1>
            <div class="next-step">
                <p>Please check your inbox for an e-mail from Polygraph (team@polygraph.net). If you cannot find the e-mail in your inbox, please check your spam folder.</p>
            </div>
          </div>
        </div>
      </div>
    <Footer />
  </div>
</template>

<script>

import Footer from '@/components/sitewide/Footer.vue';
import HeaderAuth from '@/components/sitewide/HeaderAuth.vue';

export default {
  name: 'RegistrationPending',
  components: {
          Footer,
          HeaderAuth,
  },
  computed: {
      year() {
          return new Date().getFullYear();
      }
  },
  mounted() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
</script>

<style>
    .next-step {
        margin-bottom: 200px;
        color: #B2B5BC;
        line-height: 22px;
    }
    .highlight {
        font-weight: bold;
    }
</style>
