<template>
    <header>

      <div class="header-inner header-full">

        <div class="hamburger dash-hamburger hamburger--3dy">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>

        <div class="left">
          <div class="logo-block">
            <a href="https://polygraph.net"><img src="/img/Polygraph-logo.svg" alt="Polygraph Home Page"></a>
          </div>
        </div>

        <div class="relative-block"></div>

      </div>

    </header>
</template>

<script>

export default {
    name: 'Header',
}

</script>
