<template>
    <div class="sign-in-wrap">
      <HeaderAuth />
      <div class="sign-in centered">
        <div class="form-block">
          <div class="form-block-inner">
            <h1 class="ttl center-text">Reset Password</h1>
            <p class="sub">Please type your new password.</p>
            <div v-if="errors" class="notification wrong-email">
              <div class="note-inner wrong-email-inner">
                <img src="/img/sign/danger.svg" alt="danger" class="sign-icon">
                <p>{{ errors }}</p>
              </div>
            </div>
            <form @submit="processChangePassword">
                <div class="form-group">
                  <label for="password" class="raz">
                    <input v-model="form.password" type="password" maxlength="50" id="password" placeholder=" " />
                    <i class="far fa-eye" id="togglePassword"></i>
                    <span>Password</span>
                  </label>
                  <p class="error" v-if="formErrors.passwordEmpty">Password cannot be blank.</p>
                  <p class="error" v-if="formErrors.passwordTooLong">Password must be shorter than 50 characters.</p>
                </div>
              <div class="form-grouup">
                <input type="submit" :value="submitButton" :disabled="sending">
              </div>
            </form>
        </div>
        </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/sitewide/Footer.vue';
import HeaderAuth from '@/components/sitewide/HeaderAuth.vue';

export default {
    name: 'ForgotPasswordChange',
    components: {
        Footer,
        HeaderAuth,
    },
    data() {
        return {
            form: {
                password: '',
            },
            formErrors: {
                passwordEmpty: false,
                passwordTooLong: false,
            },
            code: null,
            changePasswordToken: null,
            sending: false,
            errors: null
        }
    },
    created() {

        this.code = this.$route.params.code;
        this.changePasswordToken = this.$route.params.changePasswordToken;

        if (!/^[a-z0-9]+$/i.test(this.code) || (this.code && this.code.length !== 25)) {

            return this.errors = "Invalid code."

        } else if (!this.changePasswordToken) {

            return this.errors = "Missing token."

        }

    },
    methods: {
        async processChangePassword(evt) {

            evt.preventDefault();

            this.formErrors = this.validateForm(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) return;

            this.sending = true;

            try {
                await this.$http.post('/authentication/password/update',
                    {
                        code: this.code,
                        token: this.changePasswordToken,
                        timezone: this.getTimezone(),
                        password: this.form.password
                    }
                );

                this.$router.push({
                    name: 'Login',
                    params: { password: true }
                });

            } catch(error) {

                if (error.response.data.error) {

                    this.errors = error.response.data.error;

                } else {
                    this.errors = error.response.data.errors;
                }

            } finally {
                this.sending = false;
            }

        },
        getTimezone() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        },
        validateForm(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.password)
              errors.passwordEmpty = true;

            if (form.password.length > 50)
              errors.passwordTooLong = true;

            return errors;
        }

    },
    watch: {
        'form.email'() {
            if (this.formErrors.emailEmpty || this.formErrors.emailTooLong) {
              this.formErrors.emailEmpty = false;
              this.formErrors.emailTooLong = false;
            }
        },
    },
    mounted() {
        const togglePassword = document.querySelector('#togglePassword');
        const password = document.querySelector('#password');
        togglePassword.addEventListener('click', function() {
            // toggle the type attribute
            const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            password.setAttribute('type', type);
            // toggle the eye slash icon
            this.classList.toggle('fa-eye-slash');
        });
    },
    computed: {
        year() {
            return new Date().getFullYear();
        },
        submitButton() {
            if (this.sending == true) {
                return "Please wait...";
            } else {
                return "Submit";
            }
        },
    },
}
</script>

<style>
    .error, .error-large {
        margin: 2px 0 0 0 !important;
        /*color: #FF7F7F !important;;*/
        color: rgba(239, 160, 11, var(--tw-text-opacity)) !important;
    }
    .error-large {
        font-size: large;
        padding-bottom: 15px;
    }
</style>
