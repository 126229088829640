<template>
  <div class="py-8 p-4 min-h-screen w-screen flex items-center justify-center text-white bg-conic-dark">
      <div class="flex flex-col prose prose text-center max-w-sm">
          <img src="/assets/brand/polygraph-logo-white.svg" alt="Polygraph">
          <h2>Reset Password</h2>
          <p>Check your e-mail inbox for further instructions.<br />
          (If you cannot find an e-mail from us in your inbox, please check your spam folder.)</p>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ForgotPasswordPending'
}
</script>
