<template>
    <div class="sign-in-wrap">
      <HeaderAuth />
      <div class="sign-in centered">
        <div class="form-block">
          <div class="form-block-inner">
            <h1 class="ttl center-text">Forgot Password</h1>
            <p class="sub">Enter the e-mail address you used to sign up and we'll send you a link to reset your password.</p>
            <div v-if="error" class="notification wrong-email">
              <div class="note-inner wrong-email-inner">
                <img src="/img/sign/danger.svg" alt="danger" class="sign-icon">
                <p>The user "{{ form.email }}" does not exist.</p>
              </div>
            </div>
            <form @submit="processForgotPassword">
              <div class="form-group">
                <label class="raz">
                    <input v-model="form.email" type="email" maxlength="50" placeholder=" ">
                  <span>E-mail Address</span>
                </label>
                <p class="error" v-if="formErrors.emailEmpty">E-mail Address is required.</p>
                <p class="error" v-if="formErrors.emailInvalid">Invalid E-mail Address.</p>
                <p class="error" v-if="formErrors.emailTooLong">E-mail Address must be shorter than 50 characters.</p>
              </div>
              <div class="form-grouup">
                <input type="submit" :value="submitButton" :disabled="sending">
              </div>
            </form>
        </div>
        </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/sitewide/Footer.vue';
import HeaderAuth from '@/components/sitewide/HeaderAuth.vue';

export default {
    name: 'ForgotPassword',
    components: {
        Footer,
        HeaderAuth,
    },
    data() {
		return {
            form: {
                email: ''
            },
            formErrors: {
                emailEmpty: false,
                emailInvalid: false,
                emailTooLong: false,
            },
            sending: false,
            error: null,
            errors: null
        }
    },
    methods: {
        validateForm(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.email)
              errors.emailEmpty = true;

            if (form.email.length > 50)
              errors.emailTooLong = true;

            if (form.email && !(/^\S+@\S+\.\S+$/.test(form.email)))
              errors.emailInvalid = true;

            return errors;
        },
        async processForgotPassword(evt) {

            evt.preventDefault();

            this.formErrors = this.validateForm(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) return;

            this.sending = true;

            try {
                await this.$http.post('/authentication/password/forgot',
                    {
                        email: this.form.email
                    }
                );

                this.$router.push({
                    name: 'Login',
                    params: {
                        "success": true,
                        "email": this.form.email
                    }
                });
            } catch(error) {
                if (error.response.data.error) {

                    this.error = error.response.data.error;
                    this.submitButton = 'Reset Password';

                } else {
                    this.errors = error.response.data.errors;
                }
            } finally {
                this.sending = false;
            }

        },
    },
    computed: {
        year() {
            return new Date().getFullYear();
        },
        submitButton() {
            if (this.sending == true) {
                return "Please wait...";
            } else {
                return "Reset Password";
            }
        },
    },
    watch: {
        'form.email'() {
            if (this.formErrors.emailEmpty || this.formErrors.emailInvalid || this.formErrors.emailTooLong) {
              this.formErrors.emailEmpty = false;
              this.formErrors.emailInvalid = false;
              this.formErrors.emailTooLong = false;
            }
        },
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
</script>
