<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard">
        <Sidebar :reports="false" :addWeb="hasSub" :webButtonActive="false" :googleButtonActive="false" :settingsButtonActive="true" />

        <div class="dash-content">
            <h1 class="ttl">Settings</h1>

            <div class="navigation-block">
              <div>
                <router-link :to="{ name: 'Settings' }">Account</router-link>
              </div>
              <div>
                <router-link :to="{ name: 'Subscription' }">Subscription</router-link>
              </div>
              <div class="active">
                <router-link :to="{ name: 'Usage' }">Usage</router-link>
              </div>
            </div>

            <h1 class="ttl">Usage</h1>

            <div v-if="!ready" class="desc">
              <p>Fetching data, this can take up to 30 seconds...</p>
            </div>

            <div v-if="ready" class="dash-inner-content">

              <div class="desc" style="padding-bottom: 30px;">
                <p>The graphs below show the number of clicks analysed by Polygraph since the beginning of the current month.</p>
                <p>Your monthly allowance is {{ Number(allowance).toLocaleString() }} clicks.</p>
              </div>

              <div class="usage">
                <div class="group">
                  <div class="col1">All websites</div>
                  <div class="col2" :class="{'usage-red': total == 100, 'usage-orange': total >= 80 && total < 100, 'usage-green': total < 80 }" :style="{ 'height': h + 'px', 'width': (total > 2 ? total / 2 : 1) + '%' }">{{ total }}%</div>
                  <div class="col3">{{ Number(count).toLocaleString() }} clicks</div>
                </div>
              </div>

              <div v-if="total == 100" style="padding-bottom: 30px;">
                <p><a href="https://dashboard.polygraph.net/settings/subscription/upgrade">Upgrade your monthly allowance</a> to continue analysing your clicks for fraud. </p>
              </div>

              <div class="usage">
                <div class="group" v-for="website in websites" :key="website.domain">
                  <div class="col1">{{ website.domain }}</div>
                  <div class="col2" :class="{'usage-red': websiteTotal(website.count) == 100, 'usage-orange': websiteTotal(website.count) >= 80 && websiteTotal(website.count) < 100, 'usage-green': websiteTotal(website.count) < 80 }" :style="{ 'height': h + 'px', 'width': (websiteTotal(website.count) > 2 ? websiteTotal(website.count) / 2 : 1) + '%' }">{{ websiteTotal(website.count) }}%</div>
                  <div class="col3">{{ Number(website.count) > 0 ? Number(website.count).toLocaleString() : '' }} {{ Number(website.count) > 1 ? 'clicks' : Number(website.count) > 0 ? 'click' : '' }}</div>
                </div>
              </div>

            </div>

        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Settings',
    data() {
        return {
            ready: false,
            error: null,
            errors: null,
            h: 24,
            allowance: 0,
            websites: null,
            subStatus: false,
            controller: null
        }
    },
    components: {
        Header,
        Sidebar
    },
    async created() {

        // this is from Websites, should move to shared code
        // need this as he may cancel his existing sub so need to redirect him
        // check his subscription
        try {
            let response = await this.$http.get('/billing/subscription/status');

            this.$store.dispatch('setPaid', response.data.result.paid == true);

            if (response.data.result.expired && this.clicks) {
                // no active subscription
                this.subStatus = false;

                if (this.clicks == "500") {
                    let url = '/billing/subscription/create';

                    try {
                        await this.$http.post(url,
                            {
                                price_id: 'price_free',
                                product_id: 'prod_free',
                            }
                        );

                    } catch(error) {
                        this.error = error.response.data.error;
                    }

                } else {
                    return this.$router.push({
                        name: 'FirstSubscription',
                        params: { clicks: this.clicks }
                    });
                }

            } else if (response.data.result.expired) {
                // we don't know their clicks
                // this is because register -> e-mail -> close browser -> login, so we lost his clicks variable
                // need to fetch it from database

                this.subStatus = false;

                try {
                    let response = await this.$http.get('/authentication/package');
                    this.clicks = response.data.clicks.clicks;

                    return this.$router.push({
                        name: 'FirstSubscription',
                        params: { clicks: this.clicks }
                    });

                } catch(error) {
                    this.error = error;
                }
            } else {
                this.subStatus = true;
            }
        } catch(error) {
            this.error = error;
        }

        try {
            let response = await this.$http.get('/billing/allowance');
            this.allowance = response.data.result;
        } catch(error) {
            this.error = error;
        }

        this.controller = new AbortController();

        try {
            let response = await this.$http.get('/billing/usage', { signal: this.controller.signal });
            this.websites = response.data.result;
        } catch(error) {
            this.error = error;
        }

        this.ready = true;
    },
    computed: {
      total() {
        let total = 0;
        for(let i = 0; i < this.websites.length; i++) {
            total+= this.websites[i].count;
        }
        return Math.ceil((total / this.allowance) * 100) > 100 ? 100 : Math.ceil((total / this.allowance) * 100);
      },
      count() {
        let count = 0;
        for(let i = 0; i < this.websites.length; i++) {
            count+= this.websites[i].count;
        }
        return count;
      },
      hasSub() {
          return this.subStatus;
      },
    },
    methods: {
      websiteTotal(count) {
        return Math.ceil((count / this.allowance) * 100) > 100 ?  100 : Math.ceil((count / this.allowance) * 100);
      },
    },
    async beforeDestroy() {
      // kill any running queries
      this.controller.abort();

      if (!this.ready) {
        try {
            await this.$http.get('/dashboard/website/polygraph.net/exit');
        } catch(error) {
            console.log(error);
        }
      }
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
            $('.dash-reports').toggleClass('open');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
            $('.dash-reports').removeClass('open');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();

        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

    }
}
</script>

<style scoped>

    .usage {
      width:100%;
    }

    .group {
      margin-bottom: 20px;
    }

    .usage-green {
      border-radius: 5px;
      background: linear-gradient(to right, #2E8B57, #161F32)!important;
    }

    .usage-orange {
      border-radius: 5px;
      background: linear-gradient(to right, #CD853F, #161F32)!important;
    }

    .usage-red {
      border-radius: 5px;
      background: linear-gradient(to right, #B22222, #161F32)!important;
    }

    .col1, .col2, .col3 {
      display: inline-block;
    }

    .col1, .col3 {
      width: 10%;
    }

    .col1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 20px;
      color: #fff;
    }

    .col2 {
      text-align: center!important;
      color: #ccc;
      line-height: 24px;
      width: 50%;
    }

    .col3 {
      padding-left: 20px;
      color: #B2B5BC;
    }


</style>
