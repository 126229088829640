<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard dashboard-keywords">
        <Sidebar :reports="true" :addWeb="true" :webButtonActive="false" :googleButtonActive="false" :website="website" @finished="getKeywords"/>
        <div class="dash-content">

          <h1 class="ttl">Keywords</h1>
          <!--div v-if="high && high.length || medium && medium.length" class="desc">The tables below shows your high risk and medium risk ad keywords from the past 30 days.</div-->
          <div v-if="!newAccount" class="desc">
              The tables below show your high risk and medium risk ad keywords from the past 30 days.
          </div>

          <div class="dash-inner-content">

            <div v-if="!newAccount" class="p-block">
              <p><span>High Risk</span> are your ad keywords which are being actively targeted by click fraud gangs. This means if you
              continue using these keywords, it is likely you will continue experiencing click fraud.</p>
            </div>

            <div v-if="!newAccount" class="p-block">
              <p><span style="color:#E89347">Medium Risk</span> are your ad keywords which have been targeted by click fraud gangs in the
                past six months, however they have not been targeted in the past 30 days. You should continue using these keywords with caution.</p>
            </div>

            <div v-if="!newAccount" class="p-block">
              <p><a href="https://polygraph.net/articles/how-does-removing-ad-keywords-protect-your-campaigns-from-click-fraud.html" target="_new">How does removing ad keywords protect your campaigns from click fraud?</a></p>
            </div>

            <div v-if="!newAccount" class="card-list" style="margin-top: 20px">
              <div class="card high-risk">
                <h2><img src="/img/dashboard/keywords/danger.svg" alt="icon">High Risk</h2>
                <div v-if="ready">
                  <ul v-if="high.length">
                    <li v-for="h in high" :key="h.keyword">
                      <span v-if="h.count && website=='pangeamoneytransfer.com'">{{ h.keyword }} ({{ h.count }})</span>
                      <span v-else-if="h.count"><router-link :to="{ name: 'Refunds', params: { keyword: h.keyword }, query: { keyword: clean(h.keyword) } }">{{ h.keyword }} ({{ h.count }})</router-link></span>
                      <span v-else>{{ h.keyword }}<sup>&#8224;</sup></span>
                    </li>
                  </ul>
                  <ul v-else>
                    <li class="normal-none">No high risk keywords.</li>
                  </ul>
                </div>
                <div v-else class="normal-none">
                  <span class="white">Loading...</span>
                </div>
              </div>

              <div v-if="!newAccount" class="card mid-risk">
                <h2><img src="/img/dashboard/keywords/info-circle.svg" alt="icon">Medium Risk</h2>
                <div v-if="ready">
                  <ul v-if="medium.length">
                    <li v-for="m in medium" :key="m.keyword">
                      <span v-if="m.count && website=='pangeamoneytransfer.com'">{{ m.keyword }} ({{ m.count }})</span>
                      <span v-else-if="m.count"><router-link :to="{ name: 'Refunds', params: { keyword: m.keyword }, query: { keyword: clean(m.keyword) } }">{{ m.keyword }} ({{ m.count }})</router-link></span>
                      <span v-else>{{ m.keyword }}<sup>&#8224;</sup></span>
                    </li>
                  </ul>
                  <ul v-else>
                    <li class="normal-none">No medium risk keywords.</li>
                  </ul>
                </div>
                <div v-else>
                  <span class="white">Loading...</span>
                </div>
              </div>
            </div>

            <!--

            three scenarios:

            new account, so no results yet
            isn't using the UTMs <- no traffic and not a new account
            is using the UTMs but no fraud yet <- traffic but not fraud

            need to add reminders to the users to use UTMs

            -->

            <div v-if="newAccount" class="warning-msg" style="margin-top: 20px;">
              <img src="/img/dashboard/error_sign.svg" alt="error">
              <p>We are currently initializing your keyword data. This typically takes new accounts up to 24 hours.
                  <br><br>Please ensure your campaign URLs are using the UTM parameters <span>utm_campaign</span>, <span>utm_term</span> and <span>utm_source</span>. These UTM parameters are required by the Polygraph service.
                  <br><br>Not sure what UTMs are? We've written an article to help you get started: <a href="https://polygraph.net/articles/what-are-UTMs-and-why-should-they-be-used.html" target="_blank">What are UTMs and why should they be used?</a>
              </p>
            </div>

          </div>

          <div class="desc padded" v-if="zero">
            &#8224;Other Polygraph customers have experienced click fraud when using this keyword.
          </div>

        </div>

      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Keywords',
    data() {
        return {
            ready: false,
            campaigns: [],
            selectedCampaigns: [],
            error: null,
            showModal: false,
            newAccount: false,
            high: [],
            medium: [],
            zero: null,
            controller: null
        }
    },
    props: ['website'],
    components: {
        Header,
        Sidebar
    },
    async created() {
        // make sure he is authorised to view this website
        try {
            let response = await this.$http.get('/dashboard/website/' + this.website + '/verify');
            this.active = response.data.result.active;
        } catch(error) {
            try {
                await this.$store.dispatch('logout');
                this.$router.push({ name: 'Login'});
            } catch(error) {
                // do nothing
            }
        }
    },
    methods: {
        clean(str) {
          return str.replace(/ /g, '-');
        },
        async getKeywords() {

            this.ready = false;

            // see if cached
            if (this.$store.getters.getKeywords && this.$store.getters.getKeywords[this.website]) {
              this.high = this.$store.getters.getKeywords[this.website].high;
              this.medium = this.$store.getters.getKeywords[this.website].medium;
            } else {

              this.controller = new AbortController();

              try {
                  let response = await this.$http.get('/dashboard/website/' + this.website + '/keywords', { signal: this.controller.signal });

                  if (!Object.keys(response.data.result).length) {
                      // no keywords
                      // check if new account
                      let response = await this.$http.get('/dashboard/account');

                      if (response.data.result == true) {
                          this.newAccount = true;
                      }

                  } else {
                      let keywords = response.data.result;
                      for (let i  = 0; i < keywords.length; i++) {
                          if (!keywords[i].count) this.zero = true;

                          if (keywords[i].risk_level == "high") {
                              this.high.push({ keyword: keywords[i].utm_term, count: keywords[i].count });
                          } else if (keywords[i].risk_level == "medium") {
                              this.medium.push({ keyword: keywords[i].utm_term, count: keywords[i].count });
                          }
                      }

                      // save to store
                      let data = this.$store.getters.getKeywords || {};
                      data[this.website] = { high: this.high, medium: this.medium };
                      await this.$store.dispatch('setKeywords', data);

                  }

                  this.ready = true;

              } catch(error) {
                  console.log(error);
              }

            }

            this.ready = true;
        }

    },
    async beforeDestroy() {
      // kill any running queries
      if (this.controller) this.controller.abort();

      if (!this.ready) {
        try {
            await this.$http.get('/dashboard/website/' + this.website + '/exit');
        } catch(error) {
            console.log(error);
        }
      }
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
            $('.dash-reports').toggleClass('open');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
            $('.dash-reports').removeClass('open');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }
    }
}
</script>

<style scoped>
.padded {
    padding-top: 24px;
}

.white {
  color: #fff;
}

li a {
  color: #fff;
}
</style>
