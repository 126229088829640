<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard dashboard-keywords">
        <Sidebar :reports="false" :addWeb="false" :websitesButton="false" />
        <div class="dash-content">
            <h1 class="ttl">Settings</h1>

            <div class="navigation-block">
              <div>
                <router-link :to="{ name: 'Settings' }">Account</router-link>
              </div>
              <div class="active">
                <router-link :to="{ name: 'Subscription' }">Subscription</router-link>
              </div>
              <div>
                <router-link :to="{ name: 'Usage' }">Usage</router-link>
              </div>
            </div>

            <div>
              <ul class="breadcrumb">
                <li><router-link :to="{ name: 'Subscription' }">Subscription</router-link></li>
                <li>Change Card</li>
              </ul>
            </div>

            <div class="dash-inner-content">

                <form @submit="submitForm">
                  <div class="settings-block mb-16" style="padding: 12px;">
                    <div class="settings-block-inner">
                      <div class="settings-block-form">
                        <div class="settings-form-group payment-card-details">
                          <div class="settings-form-column flex-start width-100-percent">
                            <div class="margin-both-8 width-100-percent">
                              <input v-model="form.fullName" type="text" id="name" maxlength="50" placeholder="Name on credit card" class="input-card-details">
                            </div>
                          </div>
                        </div>
                        <span v-if="formErrors.fullNameEmpty" class="error">Name is required.</span>
                        <span v-if="formErrors.fullNameTooLong" class="error">Name must be shorter than 255 characters.</span>
                      </div>
                    </div>
                  </div>

                  <div class="settings-block mb-16" style="padding: 12px;">
                    <div class="settings-block-inner">
                      <div class="settings-block-form">
                        <div class="settings-form-group payment-card-details credit-card">

                          <div class="settings-form-column flex-start m-width-200">
                            <div class="margin-both-8 width-100-percent">
                              <div id="card-element" class="input-card-details"></div>
                            </div>
                          </div>

                        </div>
                        <span v-if="formErrors.card" class="error">{{ formErrors.card }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="add-payment mb-16">
                      <button :disabled="sending" class="block">{{ buttonText }}</button>
                  </div>

                </form>

            </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'NewCard',
    data() {
        return {
            buttonText: "Save Card",
            form: {
                fullName: ''
            },
            formErrors: {
                fullNameEmpty: false,
                fullNameTooLong: false,
                card: false
            },
            sending: false,
            stripe: '',
            cardElement: '',
            error: null,
            key: null,
            secret: null,
            ready: false
        }
    },
    components: {
        Header,
        Sidebar
    },
    async created() {
        try {
            const response = await this.$http.post('billing/card/create');
            this.secret = response.data.result.secret;
            this.key = response.data.result.key;
            this.ready = true;
        } catch(error) {
                this.error = error.response.data.error;
        }
    },
    watch: {
      key() {
          this.stripe = window.Stripe(this.key);

          const elements = this.stripe.elements();

          let style = {
            base: {
                fontFamily: "'Inter', sans-serif",
                color: '#fff',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: '#161F32',
                '::placeholder': {
                  color: '#fff',
                },
                ':-webkit-autofill': {
                  color: '#fff',
                },
            },
          };

          this.cardElement = elements.create('card', {
            style: style
          });
          this.cardElement.mount('#card-element');
      }
    },
    methods: {
        async submitForm(evt) {
            evt.preventDefault();

            if (this.sending == true) return;

            this.sending = true;

            this.clearErrors();

            this.formErrors = this.validateForm(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) {
                this.sending = false;
                return;
            }

            this.buttonText = "Please wait...";

            // everything ok
            // create payment method and confirm payment intent

            let result = null;

            try {
                result = await this.stripe.confirmCardSetup(this.secret, {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: {
                            name: this.form.fullName,
                        }
                    }
                });
            } catch(error) {
                this.error = error;
            }

            if (result.error) {
                this.sending = false;
                this.buttonText = "Save Card";
                this.formErrors.card = result.error.message;
            } else {

                // save card
                try {
                    await this.$http.post('billing/card/create', {
                        paymentMethod: result.setupIntent.payment_method
                    });
                } catch(error) {
                    this.error = error;
                }

                return this.$router.push({
                    name: 'Subscription',
                    params: { 'reload': true }
                });
            }
        },
        clearErrors() {

            this.error = null;
            this.formErrors.fullNameEmpty = false;
            this.formErrors.fullNameTooLong = false;
            this.formErrors.card = false;

        },
        validateForm(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.fullName)
              errors.fullNameEmpty = true;

            if (form.fullName.length > 255)
              errors.fullNameTooLong = true;

            return errors;
        }
    },
    mounted() {
        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }
    }
}
</script>

<style>
    .error, .error-large {
        margin: 2px 0 0 0 !important;
        color: #FF7F7F !important;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 0px 8px;
    }
    .error-large {
        font-size: large;
        text-align: center;
    }
    .block {
        margin: 0 auto;
        align-items: center;
        border: 1px solid #2B3446;
        border-radius: 12px;
        background-color: #03ADEE;
        height: 50px;
        width: 100%;
        max-width: 575px;
        cursor: pointer;
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
        color: #ffff;
    }
    .block:hover {
        background-color: #008BD0;
        transition: 0.3s ease-in-out;
    }
</style>
