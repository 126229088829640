<template>
  <div>
    <Header />
    <div class="dash-overflow"></div>
    <div class="dashboard">
      <Sidebar :reports="true" :addWeb="true" :webButtonActive="false" :googleButtonActive="false" :website="website" @finished="getEverything"/>
      <div class="dash-content">
        <h1 class="ttl">Refunds</h1>
      <div v-if="!filter && filter != null" class="desc" style="padding-bottom: 30px;">
        The table below contains the details of every fraudulent click {{ website }} has received. You can use this data to request refunds from the ad platforms you are using.
        <p><br /><a href="https://polygraph.net/articles/how-to-get-click-fraud-refunds-from-google-ads.html" target="_new">How to get click fraud refunds from Google Ads?</a> &nbsp; <a href="https://polygraph.net/articles/how-to-get-click-fraud-refunds-from-microsoft-ads.html" target="_new">How to get click fraud refunds from Microsoft Ads (Bing Ads)?</a></p>
      </div>
      <div v-else-if="filter && $route.params.keyword" class="desc" style="padding-bottom: 48px;">
        The table below contains the details of the fraudulent clicks {{ website }} has received where the ad keyword targeted was "{{ this.filter }}" (<a href="javascript:window.location.reload(true)"><span class="remove">remove filter</span></a>).
      </div>
      <div v-else class="desc" style="padding-bottom: 48px;">
        The table below contains the details of the fraudulent clicks {{ website }} has received from {{ this.filter || 'visitors with no referrer value' }} (<a href="javascript:window.location.reload(true)"><span class="remove">remove filter</span></a>).
      </div>

      <div class="dash-inner-content">

        <div class="mob flex-center white-text mobile-filter">
          <!--div class="flex-center">
            <div>Sources:</div>
            <div class="filter-dropdown-menu">
                <form v-if="sources">
                    <select v-model="selected">
                        <option v-for="source in sources" :key="source.utm_source" :value="source.utm_source">{{ source.utm_source }}</option>
                      </select>
                </form>
            </div>
          </div-->

          <div class="period-selection dropdown">
            <span class="dropdown-active">This month</span>
            <ul style="display: none;">
                <li @click="updateTable(0)"><span>This month</span></li>
                <li @click="updateTable(1)"><span>{{ getMonth(1) }}</span></li>
                <li @click="updateTable(2)"><span>{{ getMonth(2) }}</span></li>
                <li @click="updateTable(3)"><span>{{ getMonth(3) }}</span></li>
                <li @click="updateTable(4)"><span>{{ getMonth(4) }}</span></li>
                <li @click="updateTable(5)"><span>{{ getMonth(5) }}</span></li>
                <li @click="updateTable(6)"><span>Past 6 months</span></li>
            </ul>
          </div>

          <JsonCSV v-if="frauds" :data="fraudsNice">
                <div class="download-csv-btn">Download CSV</div>
            </JsonCSV>

        </div>

        <div class="overflow-x-auto">

          <div class="flex-center white-text desktop-filter">

              <div class="period-selection">
                <ul>
                    <li @click="updateTable(0)" class="active">This month</li>
                    <li @click="updateTable(1)"><span>{{ getMonth(1) }}</span></li>
                    <li @click="updateTable(2)"><span>{{ getMonth(2) }}</span></li>
                    <li @click="updateTable(3)"><span>{{ getMonth(3) }}</span></li>
                    <li @click="updateTable(4)"><span>{{ getMonth(4) }}</span></li>
                    <li @click="updateTable(5)"><span>{{ getMonth(5) }}</span></li>
                    <li @click="updateTable(6)"><span>Past 6 months</span></li>
                </ul>
              </div>

            <!--div class="flex-start">
              <div>Source:</div>
              <div class="filter-dropdown-menu">
                  <form v-if="sources">
                      <select v-model="selected">
                          <option v-for="source in sources" :key="source.utm_source" :value="source.utm_source">{{ source.utm_source }}</option>
                        </select>
                    </form>
              </div>
            </div-->

            <JsonCSV v-if="csvReady" :data="csvFraud">
                  <div class="margin-left-8 download-csv-btn">{{ CSV }}</div>
            </JsonCSV>
            <div v-if="!csvReady" class="margin-left-8 download-csv-btn" @click="getCsv">{{ CSV }}</div>

          </div>

          <div v-if="!ready" class="refunds-table">
              <div class="heading">
                <div class="label-six-columns">Date/Time (Local)</div>
                <div class="label-six-columns">Date/Time (Unix)</div>
                <div class="label-six-columns">IP Address</div>
                <div class="label-six-columns">Source</div>
                <div class="label-six-columns">Campaign</div>
                <div class="label-six-columns">Keyword</div>
                <div class="label-six-columns">Referrer</div>
                <div class="label-six-columns">Reason</div>
                <div class="label-six-columns">Click ID</div>
              </div>

              <div class="table-body">
                <div class="b-six-columns">Loading...</div>
              </div>
          </div>

          <div v-if="ready && frauds && frauds.length" class="refunds-table">

            <div class="heading">
              <div class="label-six-columns">Date/Time (Local)</div>
              <div class="label-six-columns">Date/Time (Unix)</div>
              <div class="label-six-columns">IP Address</div>
              <div class="label-six-columns">Source</div>
              <div class="label-six-columns">Campaign</div>
              <div class="label-six-columns">Keyword</div>
              <div class="label-six-columns">Referrer</div>
              <div class="label-six-columns">Reason</div>
              <div class="label-six-columns">Click ID</div>
            </div>

            <div v-for="fraud in frauds" :key="fraud.date" class="table-body">
              <!--div class="b-six-columns">{{ fraud.visited_at }}</div-->
              <!--div class="b-six-columns">{{ (new Date(parseInt(new Date(new Date(fraud.visited_at_sorting*1000)).setHours(new Date(fraud.visited_at_sorting*1000).getHours() + 0)))).toString() }}</div-->
              <div class="b-six-columns">{{ excelFriendly(new Date(fraud['Date/Time (Unix)']*1000)) }}</div>
              <div class="b-six-columns">{{ fraud['Date/Time (Unix)'] }}</div>
              <!--div class="b-six-columns"><span style="color: transparent; text-shadow: 0 0 5px white;">{{ fraud.ip }}</span></div-->
              <div class="b-six-columns">{{ fraud['IP Address'] }}</div>
              <div class="b-six-columns">{{ fraud['Source'] }}</div>
              <!--div class="b-six-columns"><span style="color: transparent; text-shadow: 0 0 5px white;">{{ fraud.utm_campaign }}</span></div-->
              <div class="b-six-columns">{{ fraud['Campaign'] }}</div>
              <div class="b-six-columns">{{ fraud['Keyword'] }}</div>
              <div class="b-six-columns">{{ fraud['Referrer'] }}</div>
              <div class="b-six-columns">{{ fraud['Reason'] }}</div>
              <div class="b-six-columns">{{ fraud['Click ID'] }}</div>
            </div>

            <div v-if="total==500" @click="loads" class="load-more">{{ loading }}</div>

            <div v-if="!frauds.length" class="table-body">
              <div class="b-six-columns">No click fraud.</div>
            </div>
          </div>

          <div v-if="ready && (!frauds || !frauds.length)" class="refunds-table m-width-1070">
              <div class="heading">
                <div class="label-six-columns">Date/Time (Local)</div>
                <div class="label-six-columns">Date/Time (Unix)</div>
                <div class="label-six-columns">IP Address</div>
                <div class="label-six-columns">Source</div>
                <div class="label-six-columns">Campaign</div>
                <div class="label-six-columns">Keyword</div>
                <div class="label-six-columns">Referrer</div>
                <div class="label-six-columns">Reason</div>
                <div class="label-six-columns">Click ID</div>
              </div>

              <div class="table-body">
                <div class="b-six-columns">No click fraud.</div>
              </div>
          </div>

        </div>

      </div>


    </div>

  </div>

  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';
import JsonCSV from 'vue-json-csv';

export default {
    name: 'Refunds',
    data() {
        return {
            ready: false,
            filter: '',
            campaigns: [],
            selectedCampaigns: [],
            error: null,
            showModal: false,
            frauds: null,
            sources: null,
            month: 0,
            selected: 'All',
            currentSort:'visited_at_sorting',
            currentSortDir:'desc',
            controller: null,
            start: 0,
            limit: 500,
            filtered: false,
            loading: 'LOAD MORE',
            CSV: 'Export Month As CSV',
            total: 0,
            csvReady: false,
            csvFraud: 0,
            referer: null,
            keyword: null
        }
    },
    props: ['website'],
    components: {
            Header,
            JsonCSV,
            Sidebar
    },
    async created() {
        if (this.$route.params.referer) {
          this.referer = this.$route.params.referer;
          this.filtered = true;
          this.filter = this.$route.params.referer;
          if (this.filter == 'unknown') this.filter = null;
          this.month = this.$route.params.month;
        } else if (this.$route.params.keyword) {
          this.keyword = this.$route.params.keyword;
          this.filtered = true;
          this.filter = this.$route.params.keyword;
          this.month = 6;
        } else {
          if (this.$route.query.source) {
            this.referer = this.$route.query.source;
            this.filtered = true;
            this.filter = this.$route.query.source;
            if (this.filter == 'unknown') this.filter = null;
            this.month = 0
          } else if (this.$route.query.keyword) {
            this.keyword = this.$route.query.keyword;
            this.filtered = true;
            this.filter = this.$route.query.keyword;
            this.month = 6;
          }
        }

        // make sure he is authorised to view this website
        try {
            await this.$http.get('/dashboard/website/' + this.website + '/verify');
        } catch(error) {
            try {
                await this.$store.dispatch('logout');
                this.$router.push({ name: 'Login'});
            } catch(error) {
                // do nothing
            }
        }
    },
    computed: {
        fraudsNice() {

          let data = [];

          for (let i = 0; i < this.frauds.length; i++) {
            data.push(
              {
                'Date/Time (Local)': this.excelFriendly(new Date(this.frauds[i]['Date/Time (Unix)']*1000)),
                'Date/Time (Unix)': this.frauds[i]['Date/Time (Unix)'],
                'IP Address': this.frauds[i]['IP Address'],
                'Source': this.frauds[i]['Source'],
                'Campaign': this.frauds[i]['Campaign'],
                'Keyword': this.frauds[i]['Keyword'],
                'Referrer': this.frauds[i]['Referrer'],
                'Reason': this.frauds[i]['Reason'],
                'Click ID': this.frauds[i]['Click ID']
              }
            );
          }

          return data;
        }
    },
    methods: {
        async getCsv(evt) {

          evt.preventDefault();

          this.CSV = "Fetching data, please wait... (0%)";

          let month = this.month;
          let url = null;
          this.csvReady = false;
          this.start = 0; // reset, as pagination may have occurred

          // get a count of the records to fetch
          if (this.keyword) {
            url = '/dashboard/website/' + this.website + '/fraud?count=1&keyword=' + this.keyword + '&month=' + month + '&source=All';
          } else if (this.referer) {
            url = '/dashboard/website/' + this.website + '/fraud?count=1&referer=' + this.referer + '&month=' + month + '&source=All';
          } else {
            url = '/dashboard/website/' + this.website + '/fraud?count=1&month=' + month + '&source=' + this.selected;
          }

          try {
              let response = await this.$http.get(url);
              this.total = response.data.result;
          } catch(error) {
              this.error = error;
          }

          let frauds = [];
          let count = 1;

          // now get the records, 500 at a time
          while(this.total && this.total > 0 && this.start <= this.total) {

              if (this.keyword) {
                url = '/dashboard/website/' + this.website + '/fraud?keyword=' + this.keyword + '&month=' + month + '&source=All&start=' + this.start + '&limit=' + this.limit;
              } else if (this.referer) {
                url = '/dashboard/website/' + this.website + '/fraud?referer=' + this.referer + '&month=' + month + '&source=All&start=' + this.start + '&limit=' + this.limit;
              } else {
                url = '/dashboard/website/' + this.website + '/fraud?month=' + month + '&source=' + this.selected + '&start=' + this.start + '&limit=' + this.limit;
              }

              this.controller = new AbortController();

              let newFrauds = null;

              try {
                  let response = await this.$http.get(url, { signal: this.controller.signal });
                  newFrauds = response.data.result;
              } catch(error) {
                  this.error = error;
              }

              if (this.total >= 500 && this.limit*count < this.total)
                 this.CSV = "Fetching data, please wait... (" + Math.round(((this.limit*count)/this.total)*100) + "%)";
              else
                 this.CSV = "Fetching data, please wait... (100%)";


              if (newFrauds.length) {

                // fix date
                for (let i = 0; i < newFrauds.length; i++) {
                  this.excelFriendly(new Date(newFrauds['Date/Time (Unix)']*1000))
                }

                let allFrauds = frauds.concat(newFrauds);

                // remove duplicates
                allFrauds = allFrauds.filter((value, index, self) =>
                  index === self.findIndex((t) => (
                    t['IP Address'] === value['IP Address'] && t['Date/Time (Local)'] === value['Date/Time (Local)'] && t['Reason'] === value['Reason']
                  ))
                );

                frauds = allFrauds;

                this.start += this.limit;
                count++;

              } else {

                this.loading = null;

              }

          }

          // convert to CSV
          //let data = 'Date/Time (Local),Date/Time (Unix),IP Address,Source,Campaign,Keyword,Referrer,Reason,Click ID\n';

          function delay(time) {
            return new Promise(resolve => setTimeout(resolve, time));
          }

          if (this.total > 0) {

            for (let i = 0; i < frauds.length; i++) {
              frauds[i]['Date/Time (Local)'] = this.excelFriendly(new Date(frauds[i]['Date/Time (Unix)']*1000));
              /*
              data += this.excelFriendly(new Date(frauds[i]['Date/Time (Unix)']*1000)) + ',' +
                      frauds[i]['Date/Time (Unix)'] + ',' +
                      frauds[i]['IP Address'] + ',' +
                      (frauds[i]['Source'] != 'NA' ? frauds[i]['Source'] : '') + ',' +
                      frauds[i]['Campaign'] + ',' +
                      (frauds[i]['Keyword'] != 'NA' ? frauds[i]['Keyword'] : '') + ',' +
                      (frauds[i]['Referrer'] ? frauds[i]['Referrer'] : '') + ',' +
                      frauds[i]['Reason'].trim() + ',' +
                      (frauds[i]['Click ID'] ? frauds[i]['Click ID'] : '') + '\n';
              */
            }

            this.csvFraud = frauds;

            if (this.total < 1500) {

              delay(1500).then(() => {

                this.csvReady = true;
                this.CSV = "Ready! Click To Download";

              });

            } else {

              this.csvReady = true;
              this.CSV = "Ready! Click To Download";

            }

          } else {

            delay(1500).then(() => {

              this.csvReady = true;
              this.CSV = "No data";

            });
          }

        },
        excelFriendly(date) {
          return (
            [
              date.getFullYear(),
              this.padTwoDigits(date.getMonth() + 1),
              this.padTwoDigits(date.getDate()),
            ].join("-") +
            " " +
            [
              this.padTwoDigits(date.getHours()),
              this.padTwoDigits(date.getMinutes()),
              this.padTwoDigits(date.getSeconds()),
            ].join(":")
          );
        },
        padTwoDigits(num) {
          return num.toString().padStart(2, "0");
        },
        async loads(evt) {

          evt.preventDefault();

          this.start += this.limit;
          this.loading = 'LOADING...';
          let frauds = this.frauds;

          this.controller = new AbortController();

          let month = this.month;
          let newFrauds = null;
          let url = null;

          if (this.keyword) {
            url = '/dashboard/website/' + this.website + '/fraud?keyword=' + this.keyword + '&month=' + month + '&source=All&start=' + this.start + '&limit=' + this.limit;
          } else if (this.referer) {
            url = '/dashboard/website/' + this.website + '/fraud?referer=' + this.referer + '&month=' + month + '&source=All&start=' + this.start + '&limit=' + this.limit;
          } else {
            url = '/dashboard/website/' + this.website + '/fraud?month=' + month + '&source=' + this.selected + '&start=' + this.start + '&limit=' + this.limit;
          }

          try {
              let response = await this.$http.get(url, { signal: this.controller.signal });
              newFrauds = response.data.result;
              this.total = response.data.total;
          } catch(error) {
              this.error = error;
          }

          if (newFrauds.length) {

            let allFrauds = frauds.concat(newFrauds);

            // remove duplicates
            allFrauds = allFrauds.filter((value, index, self) =>
              index === self.findIndex((t) => (
                t['IP Address'] === value['IP Address'] && t['Date/Time (Local)'] === value['Date/Time (Local)'] && t['Reason'] === value['Reason']
              ))
            );

            this.frauds = allFrauds;

            if (this.total == 500) this.loading = 'LOAD MORE';

          } else {

            this.loading = null;

          }

        },
        sort(s) {
          if (s == this.currentSort) this.currentSortDir = this.currentSortDir == 'asc' ? 'desc' : 'asc';
          this.currentSort = s;
        },
        sortHelper(x) {
          return x.sort((a,b) => {
            let modifier = 1;
            if (this.currentSortDir === 'desc') modifier = -1;
            if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            return 0;
          });
        },
        getEverything() {
          //this.getSources();
          this.getFraud(this.month);
        },
        async getSources() {
            try {
                let response = await this.$http.get('/dashboard/website/' + this.website + '/sources-list');
                this.sources = response.data.sources;

            } catch(error) {
                this.error = error;
            }
        },
        getMonth(val) {
            const current = new Date();
            current.setMonth(current.getMonth()-val);

            // deal with leap year
            if (current.toLocaleString('default', { month: 'long' }) == new Date().toLocaleString("default", { month: "long" })) {
                // leap year
                current.setMonth(current.getMonth()-1);
                return current.toLocaleString('default', { month: 'long' });
            } else {
                return current.toLocaleString('default', { month: 'long' });
            }
        },
        async updateTable(val) {

            this.ready = false;
            this.csvReady = false;
            this.CSV = 'Export Month As CSV';
            this.start = 0;

            this.controller.abort();

            // kill any running queries
            if (!this.ready) {
              try {
                  await this.$http.get('/dashboard/website/' + this.website + '/exit');
              } catch(error) {
                  console.log(error);
              }
            }

            this.frauds = null;
            this.getFraud(val);
        },
        async getFraud(month) {

            this.ready = false;

            this.month = month;

            this.controller = new AbortController();

            // get its fraud

            let url = null;

            if (this.keyword) {
              url = '/dashboard/website/' + this.website + '/fraud?keyword=' + this.keyword + '&month=' + month + '&source=All&start=' + this.start + '&limit=' + this.limit;
            } else if (this.referer) {
              url = '/dashboard/website/' + this.website + '/fraud?referer=' + this.referer + '&month=' + month + '&source=All&start=' + this.start + '&limit=' + this.limit;
            } else {
              url = '/dashboard/website/' + this.website + '/fraud?month=' + month + '&source=' + this.selected + '&start=' + this.start + '&limit=' + this.limit;
            }

            try {
                let response = await this.$http.get(url, { signal: this.controller.signal });
                this.frauds = response.data.result;
                this.total = response.data.total;
                this.ready = true;

            } catch(error) {
                this.error = error;
                this.ready = true;
            }

        },
    },
    watch: {
        selected() {
            this.getFraud(this.month);
        }
    },
    async beforeDestroy() {
      // kill any running queries
      this.controller.abort();

      if (!this.ready) {
        try {
            await this.$http.get('/dashboard/website/' + this.website + '/exit');
        } catch(error) {
            console.log(error);
        }
      }
    },
    mounted() {

      let month = 1;

      this.$nextTick(function () {
       month = this.month;
      });

        $( document ).ready(function() {

          if (month) {
            // remove active class from whichever element currently has it
            for(let i = 1; i <= 7; i++) {
              try {
                document.querySelector("#app > div > div.dashboard > div.dash-content > div.dash-inner-content > div.overflow-x-auto > div.flex-center.white-text.m-width-1070.desktop-filter > div.period-selection > ul > li:nth-child(" + i + ")").classList.remove("active");
              } catch {
                document.querySelector("#app > div > div.dashboard > div.dash-content > div.dash-inner-content > div.overflow-x-auto > div.flex-center.white-text.desktop-filter > div.period-selection > ul > li:nth-child(" + i + ")").classList.remove("active");
              }
            }
            // add active class to "month" element
            month = month + 1;
            try {
              document.querySelector("#app > div > div.dashboard > div.dash-content > div.dash-inner-content > div.overflow-x-auto > div.flex-center.white-text.m-width-1070.desktop-filter > div.period-selection > ul > li:nth-child(" + month + ")").classList.add("active");
            } catch {
              document.querySelector("#app > div > div.dashboard > div.dash-content > div.dash-inner-content > div.overflow-x-auto > div.flex-center.white-text.desktop-filter > div.period-selection > ul > li:nth-child(" + month + ")").classList.add("active");
            }
          }

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
            $('.dash-reports').toggleClass('open');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
            $('.dash-reports').removeClass('open');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
            $('.dropdown').on('click', function(){
                let content = $('.dropdown-active + ul li.active span').text()
                $('.dropdown-active + ul').slideToggle()
                if (content) {
                    $('.dropdown-active').html(content)
                }
            })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

    }
}
</script>

<style scoped>
.web-list-block .add-site-btn, .dash-content .download-csv-btn {
    cursor: pointer;
}
.remove {
  color: #FF5656;
}

.pagination {
  padding: 100;
}

</style>
