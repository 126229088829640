<template>
    <div>
        {{ error }}
    <form @submit="submitForm">
        <div>
            <input v-model="form.fullName" type="text" maxlength="50" placeholder="Name On Credit Card" />
            <span v-if="formErrors.fullNameEmpty">Name is required.</span>
            <span v-if="formErrors.fullNameTooLong">Name must be shorter than 255 characters.</span>
        </div>
        <div id="card-element" class="card"></div>
        <div>
            <button>Save Card</button>
        </div>
    </form>
</div>
</template>

<script>

export default {
    name: 'AddNewCard',
    data() {
        return {
            form: {
                fullName: '',
            },
            formErrors: {
                fullNameEmpty: false,
                fullNameTooLong: false,
            },
            stripe: '',
            cardElement: '',
            error: null,
            key: null,
            secret: null,
            ready: false
        }
    },
    async created() {
        try {
            const response = await this.$http.post('billing/card/create');
            this.secret = response.data.result.secret;
            this.key = response.data.result.key;
            this.ready = true;
        } catch(error) {
                this.error = error.response.data.error;
        }
    },
    watch: {
      key() {
          this.stripe = window.Stripe(this.key);

          const elements = this.stripe.elements();
          this.cardElement = elements.create('card');
          this.cardElement.mount('#card-element');
      }
    },
    methods: {
        async submitForm(evt) {
            evt.preventDefault();

            if (this.sending == true) return;

            this.sending = true;

            this.clearErrors();

            this.formErrors = this.validateForm(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) {
                this.sending = false;
                return;
            }

            this.buttonText = "Please wait...";

            // everything ok
            // create payment method and confirm payment intent

            let result = null;

            try {
                result = await this.stripe.confirmCardSetup(this.secret, {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: {
                            name: this.form.fullName,
                        },
                        receipt_email: this.form.email
                    }
                });
            } catch(error) {
                this.error = error;
            }

            // save card
            try {
                await this.$http.post('billing/card/create', {
                    paymentMethod: result.setupIntent.payment_method
                });
            } catch(error) {
                this.error = error;
            }

            return this.$router.push({
                name: 'Subscription',
            });
        },
        clearErrors() {

            this.error = null;
            this.formErrors.fullNameEmpty = false;
            this.formErrors.fullNameTooLong = false;
            this.formErrors.emailEmpty = false;
            this.formErrors.emailInvalid = false;
            this.formErrors.emailTooLong = false;
            this.formErrors.card = false;

        },
        validateForm(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.fullName)
              errors.fullNameEmpty = true;

            if (form.fullName.length > 255)
              errors.fullNameTooLong = true;

            if (!form.email)
              errors.emailEmpty = true;

            if (form.email.length > 50)
              errors.emailTooLong = true;

            if (form.email && !(/^\S+@\S+\.\S+$/.test(form.email)))
              errors.emailInvalid = true;

            return errors;
        }
    },
}
</script>
