<template>
    <footer>
    <div class="footer-inner">
      <div class="footer-top">
        <div class="footer-logo-block">
          <div class="footer-logo">
            <a href="https://polygraph.net/index.html"><img src="/img/Polygraph-logo.svg" alt="Polygraph"></a>
          </div>
        </div>
        <div class="footer-menus">
          <div id="company" class="footer-menu">
            <h3>Company</h3>
            <ul>
              <li><a href="https://polygraph.net/about.html">About</a></li>
              <li><a href="https://polygraph.net/index.html#contact">Contact</a></li>
              <li><a href="https://polygraph.net/privacy-policy.html">Privacy Policy</a></li>
              <li><a href="https://polygraph.net/terms-conditions.html">Terms &amp; Conditions</a></li>
            </ul>
          </div>
          <div id="articles" class="footer-menu-articles">
              <h3><a href="https://polygraph.net/articles/">Articles</a></h3>
              <ul>
                <li><a href="https://polygraph.net/articles/what-is-click-fraud.html">What is click fraud?</a></li>
                <li><a href="https://polygraph.net/articles/how-to-prevent-click-fraud.html">How to prevent click fraud?</a></li>
                <li><a href="https://polygraph.net/articles/what-is-conversion-fraud.html">What is conversion fraud?</a></li>
                <li><a href="https://polygraph.net/articles/">More articles</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="footer-bottom">

        <div class="copy">
          &copy; Polygraph {{ year }}
        </div>

      </div>
    </div>
    </footer>
</template>

<script>

export default {
  name: 'Footer',
  computed: {
      year() {
          return new Date().getFullYear();
      }
  },
}
</script>
