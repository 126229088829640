import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

// set up Axios, same as main.js
import Axios from 'axios';
Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

import AddCode from '@/views/dashboard/AddCode.vue';
import AddNewCard from '@/views/billing/AddNewCard.vue';
import AddFirstWebsite from '@/views/dashboard/AddFirstWebsite.vue';
import AddWebsite from '@/views/dashboard/AddWebsite.vue';
import Card from '@/views/billing/Card.vue';
import FirstPayment from '@/views/dashboard/FirstPayment.vue';
import FirstSubscription from '@/views/dashboard/FirstSubscription.vue';
import ForgotPassword from '@/views/authentication/ForgotPassword.vue';
import ForgotPasswordChange from '@/views/authentication/ForgotPasswordChange.vue';
import ForgotPasswordError from '@/views/authentication/ForgotPasswordError.vue';
import ForgotPasswordPending from '@/views/authentication/ForgotPasswordPending.vue';
import ForgotPasswordVerify from '@/views/authentication/ForgotPasswordVerify.vue';
import Google from '@/views/dashboard/Google.vue';
import Help from '@/views/dashboard/Help.vue';
import Invoices from '@/views/billing/Invoices.vue';
import Keywords from '@/views/dashboard/Keywords.vue';
import Login from '@/views/authentication/Login.vue';
import Logout from '@/views/authentication/Logout.vue';
import NewCard from '@/views/dashboard/NewCard.vue';
import Payment from '@/views/dashboard/Payment.vue';
import Refunds from '@/views/dashboard/Refunds.vue';
import Suspicious from '@/views/dashboard/Suspicious.vue';
import Registration from '@/views/authentication/Registration.vue';
import RegistrationActivate from '@/views/authentication/RegistrationActivate.vue';
import RegistrationPending from '@/views/authentication/RegistrationPending.vue';
import RegistrationError from '@/views/authentication/RegistrationError.vue';
import Settings from '@/views/dashboard/Settings.vue';
import Sources from '@/views/dashboard/Sources.vue';
import Subscription from '@/views/dashboard/Subscription.vue';
import Unlink from '@/views/dashboard/Unlink.vue';
import Upgrade from '@/views/dashboard/Upgrade.vue';
import UpgradeConfirm from '@/views/dashboard/UpgradeConfirm.vue';
import Usage from '@/views/dashboard/Usage.vue';
import Website from '@/views/dashboard/Website.vue';
import Websites from '@/views/dashboard/Websites.vue';
import WebsiteSettings from '@/views/dashboard/WebsiteSettings.vue';
import Http404 from '@/views/Http404.vue';

Vue.use(VueRouter)

// hit the backend each time
const isAuthenticated = async (to, from, next) => {
    if (store.getters.isAuthenticated) {
        try {
            await Axios.get('/authentication/validate');
            return next();
        } catch(error) {
            store.dispatch('logout');
            return next('/login');
        }
    } else {
        store.dispatch('logout');
        return next('/login');
    }

}

const redirectIfAuthenticated = (to, from, next) => {
    const token = localStorage.getItem("token");
    if (token) return next('/');
    return next();
}

const routes = [
    {
        path: '/billing/add-new-card',
        name: 'AddNewCard',
        component: AddNewCard,
        beforeEnter: isAuthenticated
    },
    {
        path: '/add-first-website',
        name: 'AddFirstWebsite',
        component: AddFirstWebsite,
        beforeEnter: isAuthenticated
    },
    {
        path: '/add-first-website-code',
        name: 'AddCode',
        component: AddCode,
        beforeEnter: isAuthenticated
    },
    {
        path: '/add-website',
        name: 'AddWebsite',
        component: AddWebsite,
        beforeEnter: isAuthenticated
    },
    {
        path: '/billing/card',
        name: 'Card',
        component: Card,
        beforeEnter: isAuthenticated
    },
    {
        path: '/billing/get-invoices',
        name: 'Invoices',
        component: Invoices,
        beforeEnter: isAuthenticated
    },
    {
        path: '/',
        name: 'Websites',
        component: Websites,
        beforeEnter: isAuthenticated
    },
    {
        path: '/billing/subscription',
        name: 'FirstSubscription',
        component: FirstSubscription,
        beforeEnter: isAuthenticated
    },
    {
        path: '/billing/payment',
        name: 'FirstPayment',
        component: FirstPayment,
        beforeEnter: isAuthenticated
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/forgot-password/change',
        name: 'ForgotPasswordChange',
        component: ForgotPasswordChange,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/forgot-password/error',
        name: 'ForgotPasswordError',
        component: ForgotPasswordError,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/forgot-password/pending',
        name: 'ForgotPasswordPending',
        component: ForgotPasswordPending,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/forgot-password/verify/:code',
        name: 'ForgotPasswordVerify',
        component: ForgotPasswordVerify,
        props: true,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/google',
        name: 'Google',
        component: Google,
        beforeEnter: isAuthenticated
    },
    {
        path: '/google/unlink',
        name: 'Unlink',
        component: Unlink,
        beforeEnter: isAuthenticated
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
        beforeEnter: isAuthenticated
    },
    {
        path: '/:website/keywords',
        name: 'Keywords',
        component: Keywords,
        props: true,
        beforeEnter: isAuthenticated
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        beforeEnter: isAuthenticated
    },
    {
        path: '/settings/card/change',
        name: 'NewCard',
        component: NewCard,
        beforeEnter: isAuthenticated
    },
    {
        path: '/settings/subscription/upgrade/payment',
        name: 'Payment',
        component: Payment,
        beforeEnter: isAuthenticated
    },
    {
        path: '/:website/refunds',
        name: 'Refunds',
        component: Refunds,
        props: true,
        beforeEnter: isAuthenticated
    },
    {
        path: '/:website/suspicious',
        name: 'Suspicious',
        component: Suspicious,
        props: true,
        beforeEnter: isAuthenticated
    },
    {
        path: '/registration',
        name: 'Registration',
        component: Registration,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/registration/activate/:code/clicks/:clicks',
        name: 'RegistrationActivate',
        component: RegistrationActivate,
        props: true,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/registration/pending',
        name: 'RegistrationPending',
        component: RegistrationPending,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/registration/error',
        name: 'RegistrationError',
        component: RegistrationError,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        beforeEnter: isAuthenticated
    },
    {
        path: '/:website/sources',
        name: 'Sources',
        component: Sources,
        props: true,
        beforeEnter: isAuthenticated
    },
    {
        path: '/settings/subscription',
        name: 'Subscription',
        component: Subscription,
        beforeEnter: isAuthenticated
    },
    {
        path: '/settings/subscription/upgrade',
        name: 'Upgrade',
        component: Upgrade,
        beforeEnter: isAuthenticated
    },
    {
        path: '/settings/subscription/upgrade/confirm',
        name: 'UpgradeConfirm',
        component: UpgradeConfirm,
        beforeEnter: isAuthenticated
    },
    {
        path: '/settings/usage',
        name: 'Usage',
        component: Usage,
        beforeEnter: isAuthenticated
    },
    {
        path: '/website/:website',
        name: 'Website',
        component: Website,
        props: true,
        beforeEnter: isAuthenticated
    },
    {
        path: '/website/:website/settings',
        name: 'WebsiteSettings',
        component: WebsiteSettings,
        props: true,
        beforeEnter: isAuthenticated
    },
    {
        path: '*',
        name: 'Http404',
        component: Http404
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
