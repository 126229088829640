import Vue from 'vue';
import Vuex from 'vuex';
import authentication from './modules/authentication';
import sitewide from './modules/sitewide';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        authentication,
        sitewide
    }
});
