<template>
  <div>
      <table v-if="ready" class="centre">
          <tr>
              <th>Date</th>
              <th>Invoice Number</th>
              <th>Amount (USD)</th>
              <th>Invoice &amp; Receipt</th>
          </tr>
          <tr v-if="!invoices">
              <td colspan="4" style="text-align:center">None</td>
          </tr>
          <invoice v-for="invoice in invoices" :key="invoice.id" :invoice="invoice" />
      </table>
  </div>
</template>

<script>
import Invoice from '@/components/billing/Invoice.vue'

export default {
    name: 'Invoices',
    data() {
        return {
            ready: false,
            invoices: null,
            error: null

        }
    },
    components: {
        Invoice,
    },
    async created() {
        try {
            const response = await this.$http.get('/billing/invoices');
            this.invoices = response.data.result;
            this.ready = true;
        } catch(error) {
            this.error = error.response.data.error;
        } 
    },
}
</script>

<style>
    .centre {
        margin-left: auto;
        margin-right: auto;
    }

    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px 16px;
    }
</style>
