<template>
    <div class="sign-in-wrap">
      <HeaderAuth />
      <div class="sign-in centered">
        <div class="form-block">
          <div class="form-block-inner">
            <h1 class="ttl center-text">Log In</h1>
            <div v-if="welcome" class="notification success">
              <div class="note-inner success-inner">
                <p>Thank you for confirming your e-mail address.</p>
              </div>
            </div>
            <div v-if="success" class="notification success">
              <div class="note-inner success-inner">
                <img src="/img/sign/tick-circle.svg" alt="danger" class="sign-icon">
                <p>Password reset link was sent to {{ resetEmail }}.</p>
              </div>
            </div>
            <div v-if="password" class="notification success">
              <div class="note-inner success-inner">
                <img src="/img/sign/tick-circle.svg" alt="danger" class="sign-icon">
                <p>Password changed successfully.</p>
              </div>
            </div>
            <div v-if="error" class="notification wrong-email">
              <div class="note-inner wrong-email-inner">
                <img src="/img/sign/danger.svg" alt="danger" class="sign-icon">
                <p>{{ error }}</p>
              </div>
            </div>
            <form @submit="processLogin">
              <div class="form-group">
                <label class="raz">
                  <input v-model="form.email" type="email" maxlength="50" placeholder=" ">
                  <span>E-mail Address</span>
                </label>
                <p class="error" v-if="formErrors.emailEmpty">E-mail Address is required.</p>
                <p class="error" v-if="formErrors.emailInvalid">Invalid E-mail Address.</p>
                <p class="error" v-if="formErrors.emailTooLong">E-mail Address must be shorter than 50 characters.</p>
              </div>
              <div class="form-group">
                <label for="" class="raz">
                  <input v-model="form.password" type="password" maxlength="50" placeholder=" ">
                  <span>Password</span>
                </label>
                <p class="error" v-if="formErrors.passwordEmpty">Password cannot be blank.</p>
                <p class="error" v-if="formErrors.passwordTooLong">Password must be shorter than 50 characters.</p>
              </div>
              <div class="form-grouup">
                <input type="submit" :value="login" :disabled="sending">
              </div>
            </form>
            <div class="have-acc"><span>Forgot your password? <router-link to="/forgot-password">Reset your password</router-link></span></div>
            <div class="have-acc"><span>Don't have an account? <router-link to="/registration">Create an account</router-link></span></div>
        </div>
        </div>
        </div>
        <Footer />
    </div>
  </template>

<script>

import Footer from '@/components/sitewide/Footer.vue';
import HeaderAuth from '@/components/sitewide/HeaderAuth.vue';

export default {
    name: 'Login',
    components: {
            Footer,
            HeaderAuth,
    },
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            formErrors: {
                emailEmpty: false,
                emailInvalid: false,
                emailTooLong: false,
                passwordEmpty: false,
                passwordTooLong: false,
            },
            sending: false,
            error: null,
            errors: null,
            backendError: null,
            clicks: null,
            success: null,
            resetEmail: null
        }
    },
    created() {
        if (this.$route.params.clicks) {
            this.clicks = this.$route.params.clicks;

            if ((this.clicks != "500") && (this.clicks != "10,000") && (this.clicks != "25,000") &&
                (this.clicks != "50,000") && (this.clicks != "75,000") && (this.clicks != "100,000") &&
                (this.clicks != "200,000") && (this.clicks != "500,000")) {
                this.clicks = "500";
            }
        }

        if (this.$route.params.success) {
            this.success = true;
            this.resetEmail = this.$route.params.email;
        }
    },
    computed: {
        welcome() {
            return this.$route.params.confirmed;
        },
        password() {
            return this.$route.params.password;
        },
        login() {
            if (this.sending == true) {
                return "Logging in...";
            } else {
                return "Log In";
            }
        },
    },
    methods: {
        validateForm(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.email)
              errors.emailEmpty = true;

            if (form.email.length > 50)
              errors.emailTooLong = true;

            if (form.email && !(/^\S+@\S+\.\S+$/.test(form.email)))
              errors.emailInvalid = true;

            if (!form.password)
              errors.passwordEmpty = true;

            if (form.password.length > 50)
              errors.passwordTooLong = true;

            return errors;
        },
        async processLogin(evt) {

            evt.preventDefault();

            this.formErrors = this.validateForm(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) return;

            this.sending = true;

            try {
                const response = await this.$http.post('/authentication/login',
                    {
                        email: this.form.email,
                        password: this.form.password,
                        timezone: this.getTimezone() // get his timezone (VPN detection
                    }
                );
                this.sending = false;
                const token = response.data.result;

                await this.$store.dispatch('login', { token });

                if (this.clicks) {
                    return this.$router.push({
                        name: 'Websites',
                        params: { clicks: this.clicks }
                    });
                } else {
                    return this.$router.push({
                        name: 'Websites'
                    });
                }
            } catch(error) {

                if (error.response.data.error) {
                    this.error = error.response.data.error;
                } else {
                    this.errors = error.response.data.errors;
                }
            } finally {
                this.sending = false;
            }
        },
        getTimezone() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        },
    },
    watch: {
        'form.email'() {
            if (this.formErrors.emailEmpty || this.formErrors.emailInvalid || this.formErrors.emailTooLong) {
              this.formErrors.emailEmpty = false;
              this.formErrors.emailInvalid = false;
              this.formErrors.emailTooLong = false;
            }
        },
        'form.password'() {
            if (this.formErrors.passwordEmpty || this.formErrors.passwordTooLong) {
              this.formErrors.passwordEmpty = false;
              this.formErrors.passwordTooLong = false;
            }
        },
    }
}
</script>

<style>
    .error, .error-large {
        margin: 2px 0 0 0 !important;
        /*color: #FF7F7F !important;;*/
        color: rgba(239, 160, 11, var(--tw-text-opacity)) !important;
    }
    .error-large {
        font-size: large;
        padding-bottom: 15px;
    }
</style>
