<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard dashboard-keywords">
        <Sidebar :reports="false" :addWeb="hasSub" :websitesButton="false" />
        <div class="dash-content">
            <h1 class="ttl">Settings</h1>

            <div class="navigation-block">
              <div>
                <router-link :to="{ name: 'Settings' }">Account</router-link>
              </div>
              <div class="active">
                <router-link :to="{ name: 'Subscription' }">Subscription</router-link>
              </div>
              <div>
                <router-link :to="{ name: 'Usage' }">Usage</router-link>
              </div>
            </div>

            <div>
              <ul class="breadcrumb">
                <li><router-link :to="{ name: 'Subscription' }">Subscription</router-link></li>
                <li>Upgrade</li>
              </ul>
            </div>

            <div v-if="ready" class="dash-inner-content">

              <h1 class="ttl">Upgrade</h1>

              <div v-for="plan in plans" :key="plan.sub" class="settings-block mb-16" :class="{ 'active-block': selected.clicks == plan.clicks }">
                <div class="settings-block-inner">
                  <form class="settings-block-form padding-both-8">
                    <div class="settings-form-group padding-top-bottom-8">
                      <div class="settings-form-column">
                        <div v-if="selected.clicks == plan.clicks" class="settings-block-small-content blue-text">
                          Selected
                        </div>
                        <div class="settings-block-large-content">
                          {{ plan.title }}
                        </div>
                        <div class="settings-block-middle-content gray-text">
                          {{ plan.sub }}<br>{{ plan.sub2 }}
                        </div>
                      </div>
                      <div class="settings-form-column flex-end">
                        <div>
                          <span v-if="selected.clicks != plan.clicks" @click="upgradePlan(plan)" class="control-button approve">Select Plan</span>
                        </div>
                      </div>
                    </div>

                  </form>

                </div>
              </div>

              <div class="add-payment">
                <router-link :to="{ name: 'Subscription' }" class="add-new solid">
                  <div class="text-field">
                    <img src="/img/arrow-circle-left.svg">
                    Back
                  </div>
              </router-link>
              </div>

          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Upgrade',
    data() {
        return {
            buttonText: "Next Step",
            plans: [
                {
                    title: "CLICKS 25K",
                    sub: "Up to 25,000 ad clicks per month",
                    sub2: "$450 monthly",
                    clicks: "25,000",
                    price_id: 'price_1NkilGD9QBzGC2isxKwKG7gC',
                    product_id: 'prod_OXoORs1D2zIVQk',
                },
                {
                    title: "CLICKS 50K",
                    sub: "Up to 50,000 ad clicks per month",
                    sub2: "$800 monthly",
                    clicks: "50,000",
                    price_id: 'price_1Nkio5D9QBzGC2isVDElvXfB',
                    product_id: 'prod_OXoQncYhUar9UD',
                },
                {
                    title: "CLICKS 100K",
                    sub: "Up to 100,000 ad clicks per month",
                    sub2: "$1,500 monthly",
                    clicks: "100,000",
                    price_id: 'price_1Nkip5D9QBzGC2isygKFTGSP',
                    product_id: 'prod_OXoRRxLn5s7E2l',
                },
                {
                    title: "CLICKS 200K",
                    sub: "Up to 200,000 ad clicks per month",
                    sub2: "$2,500 monthly",
                    clicks: "200,000",
                    price_id: 'price_1NkipuD9QBzGC2isMrYo74go',
                    product_id: 'prod_OXoS7DbQVOzI9p',
                },
                {
                    title: "CLICKS 500K",
                    sub: "Up to 500,000 ad clicks per month",
                    sub2: "$4,500 monthly",
                    clicks: "500,000",
                    price_id: 'price_1NkiqhD9QBzGC2isFdRi1Jjx',
                    product_id: 'prod_OXoTsKaZsc2qP8',
                },
            ],
            selected: null,
            error: null,
            subStatus: false,
            ready: false
        }
    },
    components: {
        Header,
        Sidebar
    },
    async created() {

        // check if he has a sub
        try {
            let response = await this.$http.get('/billing/subscription/status');

            this.$store.dispatch('setPaid', response.data.result.paid == true);

            if (response.data.result.expired && this.clicks) {
                // no active subscription
                this.subStatus = false;

            } else if (response.data.result.expired) {

                this.subStatus = false;

            } else {
                this.subStatus = true;
            }
        } catch(error) {
            this.error = error;
        }


        this.selected = this.plans[1];

        let clicks = null;

        if (this.$route.params.clicks) {
            clicks = this.$route.params.clicks;

            if ((clicks != "500") && (clicks != "10,000") && (clicks != "25,000") &&
                (clicks != "50,000") && (clicks != "75,000") && (clicks != "100,000") &&
                (clicks != "200,000") && (clicks != "500,000")) {
                clicks = "25,000";
            }

        } else {

            try {
                let response = await this.$http.get('/authentication/package');
                clicks = response.data.clicks.clicks;
            } catch(error) {
                this.error = error;
            }
        }

        let found = false;

        for(let i = 0; i < this.plans.length; i++) {
            if (this.plans[i].clicks == clicks) {
                this.selected = this.plans[i];
                found = true;
            }
        }

        if (!found) {
          this.selected = {
              title: "Free Plan",
              sub: "Up to 500 ad clicks per month",
              sub2: "$0 monthly",
              clicks: "500",
              price_id: 'price_free',
              product_id: 'prod_free',
          };
        }

        this.ready = true;
    },
    methods: {
        upgradePlan(plan) {
            return this.$router.push({
                name: 'UpgradeConfirm',
                params: {
                    plan: plan,
                    existing: this.selected.clicks || 500
                }
            });
        },
    },
    computed: {
        hasSub() {
            return this.subStatus;
        },
    },
    mounted() {
        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }
    }
}
</script>

<style>
    .error, .error-large {
        margin: 2px 0 0 0 !important;
        color: #FF7F7F !important;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 2px 6px;
    }
    .error-large {
        font-size: large;
        text-align: center;
    }
</style>
