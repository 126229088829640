<template>
  <div>
      <Header />
      <div class="dash-overflow"></div>
      <div class="dashboard dashboard-keywords">
        <Sidebar :reports="false" :addWeb="true" :googleButtonActive="true" :updateSidebar="updateSidebar" />
        <div v-show="ready" class="dash-content">
          <div class="dash-inner-content">
            <div class="g-list-block">
              <h1>Google Ads Integration</h1>
              <div>
                <ul class="breadcrumb">
                  <li><router-link :to="{ name: 'Google' }">Google Ads Integration</router-link></li>
                  <li>Unlink Google Ads Account</li>
                </ul>
              </div>

              <div class="overflow-x-auto">
                <div v-if="gaccounts && gaccounts.length" class="web-table">
                  <div class="heading">
                    <div class="label-manager">Manager Account</div>
                    <div class="label-clients">Clients</div>
                    <div class="label-delete">Unlink</div>
                  </div>
                  <div v-for="account in gaccounts" :key="account.client" class="table-body">
                    <div v-if="account.manager_name && account.manager_id" class="b-manager">{{ account.manager_name }} ({{ account.manager_id }})</div>
                    <div v-else class="b-manager">-</div>
                    <div class="b-clients">
                        <span v-for="(client, index) in account.clients" :key="index">
                            <span v-if="client.client_name">{{ client.client_name }} ({{ client.client_id}})<span v-if="index<account.clients.length-1">,</span></span>
                            <span v-else>{{ client.client_id}}<span v-if="index<account.clients.length-1">,</span></span>
                        </span>
                        <span v-if="account.clients.length == 0">-</span>
                    </div>
                    <div class="b-delete" @click="del(account.manager_id)"><img src="/img/dashboard/delete.svg" alt="Delete" class="hover"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/dashboard/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';
import $ from 'jquery';

export default {
    name: 'Unlink',
    data() {
        return {
            error: '',
            ready: false,
            gaccounts: [],
            clicks: null,
            updateSidebar: 0
        }
    },
    components: {
        Header,
        Sidebar
    },
    computed: {
        token() {
            return this.$store.getters.getToken;
        }
    },
    methods: {
        async del(id) {

            try {
                await this.$http.post('/dashboard/google/delete', {
                    managerId: id
                });

                for (let i = 0; i < this.gaccounts.length; i++) {
                    if (this.gaccounts[i].manager_id == id) {
                        this.gaccounts.splice(i, 1);
                        break;
                    }
                }

            } catch(error) {
                this.error = error.response.data.error;
            }
        },
    },
    async created() {

        this.ready = false;

        // even if first time logging in, need to double check he doesn't have an existing subscription
        // as he may be logging in again using the account activation e-mail

        // check his subscription
        try {
            let response = await this.$http.get('/billing/subscription/status');

            this.$store.dispatch('setPaid', response.data.result.paid == true);

            if (response.data.result.expired && this.clicks) {
                // no active subscription
                if (this.clicks == "500") {
                    let url = '/billing/subscription/create';

                    try {
                        await this.$http.post(url,
                            {
                                price_id: 'price_free',
                                product_id: 'prod_free',
                            }
                        );

                    } catch(error) {
                        this.error = error.response.data.error;
                    }

                } else {
                    return this.$router.push({
                        name: 'FirstSubscription',
                        params: { clicks: this.clicks }
                    });
                }

            } else if (response.data.result.expired) {
                // we don't know their clicks
                // this is because register -> e-mail -> close browser -> login, so we lost his clicks variable
                // need to fetch it from database

                try {
                    let response = await this.$http.get('/authentication/package');
                    this.clicks = response.data.clicks.clicks;

                    return this.$router.push({
                        name: 'FirstSubscription',
                        params: { clicks: this.clicks }
                    });

                } catch(error) {
                    this.error = error;
                }
            }
        } catch(error) {
            this.error = error;
        }

        // at this point his subscription is ok
        // either ask him to add a website or show the main dashboard

        // get his websites
        try {
            let response = await this.$http.get('/dashboard/websites');

            this.websites = response.data.result;

        } catch(error) {
            this.error = error;
        }

        if (!this.websites.length) {
            // if no websites
            return this.$router.push({
                name: 'AddFirstWebsite'
            });
        }

        // get google accounts
        let accounts = [];
        try {
            let response = await this.$http.get('/dashboard/google/unlink');
            accounts = response.data.result;
        } catch(error) {
            this.error = error;
        }

        // will receive something like this
        // [
        //     {
        //         "Manager Name": "Test Account 1",
        //         "manager_id": 7179248925,
        //         "client_id": 5217148293,
        //         "Client Name": "Test Client 1",
        //         "Client Friendly Description": null
        //     },
        //     {
        //         "Manager Name": "Test Account 1",
        //         "manager_id": 7179248925,
        //         "client_id": 3497016452,
        //         "Client Name": "Test Client 2",
        //         "Client Friendly Description": null
        //     },
        // ]
        // need to convert it to this:
        // [
        //      {
        //          "Manager Name": "Test Account 1",
        //          "manager_id": 7179248925,
        //          "clients":
        //              [
        //                  {
        //                      "client_id": 5217148293,
        //                      "Client Name": "Test Client 1",
        //                      "Client Friendly Description": null
        //                  },
        //                  {
        //                    "client_id": 3497016452,
        //                      "Client Name": "Test Client 2",
        //                      "Client Friendly Description": null
        //                  },
        //              ]
        //      }
        // ]

        // first let's set up the manager and empty client object
        for (let i = 0; i < accounts.length; i++) {

            let obj = this.gaccounts.find(obj => obj.manager_id === accounts[i].manager_id);
            // not found
            if (obj == undefined) {
                // push manager data and empty client object

                this.gaccounts.push(
                    {
                        'manager_name': accounts[i]['Manager Name'],
                        'manager_id': accounts[i].manager_id,
                        'clients': []
                    }
                );
            }
        }

        // now let's add the client data
        for (let i = 0; i < accounts.length; i++) {

            let obj = this.gaccounts.find(obj => obj.manager_id === accounts[i].manager_id);
            // not found
            if (obj == undefined) {
                // this should never happen
            } else {
                //if (accounts[i]['Client Name'] && accounts[i].client_id) {
                // standalone accounts don't have a Client Name
                if (accounts[i].client_id) {
                    obj.clients.push(
                        {
                            'client_name': accounts[i]['Client Name'],
                            'client_id': accounts[i].client_id,
                            'friendly': accounts[i]['Client Friendly Description']
                        }
                    );
                }
            }
        }

        this.ready = true;
    },
    mounted() {

        $( document ).ready(function() {

          $('.simple-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.header-inner .right').toggleClass('open');
            $('body').toggleClass('overflow');
          });

          $('.dash-hamburger').on('click', function(){
            $(this).toggleClass('is-active');
            $('.dash-nav').toggleClass('open');
            $('body').toggleClass('overflow');
            $('.dash-overflow').toggleClass('visible');
          });

          $('.dash-overflow').on('click', function(){
            $('.dash-hamburger').removeClass('is-active');
            $('.dash-nav').removeClass('open');
            $('body').removeClass('overflow');
            $(this).removeClass('visible');
          });

          closeContentBlock();
          openLoginMenu();
          calcMainContentMargin();
          periodSelection();
          calculateContentMarginTop();
          addEventListenerForMenu();
        });

        function closeContentBlock() {
          const closeButtons = document.getElementsByClassName("close");

          for(let i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener("click", function (){
              closeButtons[i].parentElement.style.display = "none";
            });
          }
        }

        function openLoginMenu() {
          const accountMenu = document.getElementById("account-menu");
          const accountMenuPopup = document.getElementById("account-menu-popup");

          if (null !== accountMenu && null !== accountMenuPopup) {
            const toggleMenu = function() {
              accountMenuPopup.classList.toggle("hide-element");
            }

            accountMenu.addEventListener("click", function (e) {
              e.stopPropagation();
              toggleMenu();
            });

            document.addEventListener("click", function(e){
              const target = e.target;
              const itsAccountMenuPopup = target === accountMenuPopup || accountMenuPopup.contains(target);
              const itsAccountMenu = target === accountMenu;
              const menuIsActive = !accountMenuPopup.classList.contains("hide-element");

              if (!itsAccountMenuPopup && !itsAccountMenu && menuIsActive) {
                toggleMenu();
              }
            });
          }

        }

        function toggleDropdown() {
          $('.dropdown').on('click', function(){
            $('.dropdown-active + ul').slideToggle()
            let content = $('.dropdown-active + ul li.active a').text()
            $('.dropdown-active').html(content)
          })
        }
        toggleDropdown()

        function calcMainContentMargin() {
          const navigationBlock = document.getElementById("dash-navigation");
          const contentBlock = document.getElementsByClassName("dash-content");
          const footerBlock = document.getElementsByTagName("footer");

          if (
              null !== navigationBlock &&
              null !== contentBlock &&
              null !== footerBlock
          ) {
            const navigationBlockWidth = navigationBlock.offsetWidth;

            for(let i = 0; i < contentBlock.length; i++) {
              contentBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }

            for(let i = 0; i < footerBlock.length; i++) {
              footerBlock[i].style.marginLeft = navigationBlockWidth + "px";
            }
          }

        }

        function periodSelection() {
          const allSelectionsMenu = document.getElementsByClassName("period-selection");

          for(let i = 0; i < allSelectionsMenu.length; i++) {

            const selectionsMenuLi =
                allSelectionsMenu[i].getElementsByTagName("ul")[0].getElementsByTagName("li");

            for(let i = 0; i < selectionsMenuLi.length; i++) {
              let anchor = selectionsMenuLi[i];
              anchor.onclick = function () {
                for (let i = 0; i < selectionsMenuLi.length; i++) {
                  selectionsMenuLi[i].classList.remove("active");
                }
                anchor.classList.add("active");
              }
            }

          }
        }

        function calculateContentMarginTop() {
          const headerHeight = $('header').height();
          $('.blog').css('margin-top', headerHeight + 'px');
          $('.dashboard').css('margin-top', headerHeight + 'px');
          $('.single').css('margin-top', headerHeight + 'px');
        }
        window.onresize = calculateContentMarginTop;

        function addEventListenerForMenu() {
          $('.main-pages-right').children('nav').children('ul').children('li').each(function () {
            $(this).on('click', function () {
              if ($('#homepage_hamburger_menu').css('display') === 'block') {
                $('.simple-hamburger').toggleClass('is-active');
                $('.header-inner .right').toggleClass('open');
                $('body').toggleClass('overflow');
              }
            });
          });
        }

    },
}
</script>

<style>


</style>
