<template>
  <div id="app">
    <!--div id="nav">
      <router-link v-if="isAuthenticated()" to="/dashboard">Dashboard</router-link>
      <router-link v-if="!isAuthenticated()" to="/registration">Registration</router-link>
      <router-link v-if="!isAuthenticated()" to="/login">Login</router-link>
      <router-link v-if="isAuthenticated() && isPaid()" to="/billing/get-invoices">Invoices</router-link>
      <router-link v-if="isAuthenticated()" to="/billing/get-subscriptions">Subscriptions</router-link>
      <a v-if="isAuthenticated()" href="/logout" @click="logout">Logout</a>
      <language-picker :default="this.$store.getters.getLanguage" />
      <p v-if="error">{{ error }}</p>
  </div-->
    <router-view />
  </div>
</template>

<script>
//import LanguagePicker from '@/components/sitewide/LanguagePicker.vue'

export default {
    name: 'App',
    data() {
        return {
            error: null
        }
    },
    components: {
        //LanguagePicker,
    },
    methods: {        
        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        },
        isPaid() {
            return this.$store.getters.isPaid;
        }
    },
    created() {
        this.$store.dispatch('setLanguage', localStorage.getItem('language') || 'en');
    }
}
</script>

<!--style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style-->
