<template>
    <div class="dash-sidebar-menu" id="dash-navigation">
      <div class="dash-nav">
        <div class="websites">
          <h2>Websites</h2>
          <div class="add-website">
            <router-link v-if="addWeb" :to="{ name: 'AddWebsite' }" class="add-web"><img src="/img/dashboard/add_active.svg" alt="Add Site">Add Site</router-link>
            <span v-else class="add-web-disabled"><img src="/img/dashboard/add_disabled.svg">Add site</span>
          </div>
          <ul v-if="hasActive" class="website-list">
              <router-link v-for="w in activeWebsites" :key="w" :to="{ name: 'Sources', params: { website: w } }" tag="li" :class="{ 'selected': w==website }">{{ w }}</router-link>
          </ul>
        </div>
        <div v-if="hasInactive" class="disabled">
          <h2>Disabled</h2>
          <ul class="disabled-list">
            <li v-for="website in inactiveWebsites" :key="website">
                {{ website }}
            </li>
          </ul>
        </div>
        <div v-if="hasPending" class="pending">
          <h2>Pending</h2>
          <ul class="pending-list">
            <li v-for="website in pendingWebsites" :key="website">
                {{ website }}
            </li>
          </ul>
        </div>
        <div class="profile-nav-menu nav-menu">
          <ul>
            <li v-if="addWeb" :class="{ 'default': !webButtonActive, 'active': webButtonActive }">
                <a href="/"><img :src="webButtonActive ? '/img/dashboard/Site_active.svg' : '/img/dashboard/Site_white.svg'" alt="Websites">Websites</a>
            </li>
            <li v-else class="disable">
                <span><img src="/img/dashboard/Site_disable.svg" alt="Websites">Websites</span>
            </li>
            <li v-if="addWeb" :class="{ 'default': !googleButtonActive, 'active': googleButtonActive }">
                <a href="/google"><img :src="googleButtonActive ? '/img/dashboard/google_icon_menu_disable.svg' : '/img/dashboard/google_icon_menu.svg'" alt="Google Integration">Google</a>
            </li>
            <li v-else class="disable">
                <span><img src="/img/dashboard/google_icon_menu_disable.svg" alt="Google Integration">Google</span>
            </li>
            <li :class="{ 'default': !settingsButtonActive, 'active': settingsButtonActive }">
                <a href="/settings"><img :src="settingsButtonActive ? '/img/dashboard/settings_icon_menu_dark.svg' : '/img/dashboard/settings_icon_menu_light.svg'" alt="Settings">Settings</a>
            </li>
            <li :class="{ 'default': !help, 'active': help }">
                <a href="/help"><img :src="help ? '/img/dashboard/help_icon_menu_dark.svg' : '/img/dashboard/help_icon_menu_light.svg'">Help</a>
            </li>
            <li>
                <Logout />
            </li>
          </ul>
        </div>
      </div>
      <div v-if="reports" class="dash-reports">
        <h2>Reports</h2>
        <div class="main-reports-menu reports-menu">
          <ul>
            <router-link :to="{ name: 'Sources', params: { website: website } }" tag="li">
                <img :src="this.$route.name == 'Sources' ? '/img/dashboard/sources_icon_menu_dark.svg' : '/img/dashboard/sources_icon_menu_light.svg'" alt="Sources">Sources
            </router-link>
            <router-link :to="{ name: 'Keywords', params: { website: website } }" tag="li">
                <img :src="this.$route.name == 'Keywords' ? '/img/dashboard/key_icon_menu_dark.svg' : '/img/dashboard/key_icon_menu_light.svg'" alt="Keywords">Keywords
            </router-link>
            <router-link :to="{ name: 'Refunds', params: { website: website } }" tag="li">
                <img :src="this.$route.name == 'Refunds' ? '/img/dashboard/refunds_icon_menu_dark.svg' : '/img/dashboard/refunds_icon_menu_light.svg'" alt="Refunds">Refunds
            </router-link>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
import Logout from '@/components/authentication/Logout.vue';

export default {
    name: 'Sidebar',
    data() {
        return {
            websites: null,
        }
    },
    props: [
        'active',
        'addWeb',
        'googleButtonActive',
        'help',
        'reports',
        'reload',
        'settingsButtonActive',
        'updateSidebar',
        'webButton',
        'webButtonActive',
        'website'
    ],
    components: {
            Logout
    },
    async created() {
        this.getWebsites();
    },
    computed: {
        hasPending() {
            let found = this.websites && this.websites.find(w => w.traffic == '0');
            if (found) return true;
            return false;
        },
        pendingWebsites() {
            let websites = [];
            for(let i = 0; i < this.websites.length; i++) {
              if (this.websites[i].traffic == '0'){
                websites.push(this.websites[i].domain)
              }
            }
            return websites;
        },
        hasActive() {
            let found = this.websites && this.websites.find(w => w.active == '1');
            if (found) return true;
            return false;
        },
        activeWebsites() {
            let websites = [];
            for(let i = 0; i < this.websites.length; i++) {
              if (this.websites[i].active == '1' && this.websites[i].traffic == '1'){
                websites.push(this.websites[i].domain)
              }
            }
            return websites;
        },
        hasInactive() {
            let found = this.websites && this.websites.find(w => w.active == '0');
            if (found) return true;
            return false;
        },
        inactiveWebsites() {
            let websites = [];
            for(let i = 0; i < this.websites.length; i++) {
              if (this.websites[i].active == '0'){
                websites.push(this.websites[i].domain)
              }
            }
            return websites;
        }
    },
    methods: {
        async getWebsites() {
            // get the list of websites

            // see if cached
            if (!this.reload && this.$store.getters.getWebsites) {
              this.websites = this.$store.getters.getWebsites;

            } else if (!this.reload && localStorage.getItem('websites')) {

              this.websites = JSON.parse(localStorage.getItem('websites'));

            } else {

              let result = null;

              try {
                  result = await this.$http.get('/dashboard/websites');
                  this.websites = result.data.result;

                  // save to store
                  await this.$store.dispatch('setWebsites', this.websites);
                  localStorage.setItem('websites', JSON.stringify(this.websites));

              } catch(error) {
                  console.log(error);
              }

            }

            this.$emit('finished');
        }
    },
    watch: {
        updateSidebar() {
            this.getWebsites();
        }
    }
}
</script>

<style>
.website-list li {
    color: #fff;
    margin: 4px 8px;
}


.website-list li:hover, .reports-menu li:hover {
    cursor: pointer;
}

.selected {
    color: #030015 !important;
    margin: 4px 8px;
    border-radius: 6px;
    border: 0px solid #161F32;
    background-color: #03ADEE;
}

.reports-menu li.router-link-active,
.reports-menu li.router-link-exact-active {
     color: #030015 !important;
     margin: 4px 8px;
     border-radius: 6px;
     border: 0px solid #161F32;
     background-color: #03ADEE;
}

</style>
