<template>
  <div>
      {{ errors }}
    <p>Forgot Password Error</p>
    <div v-if="this.errors && this.errors.userNotExist">
    Cannot find account (userNotExist). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.insertCodeFailed">
    Processing error (insertCodeFailed). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.codeNotFound">
    Processing error (codeNotFound). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.codeAlreadyUsed">
    Processing error (codeAlreadyUsed). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.codeExpired">
    Processing error (codeExpired). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.sendEmailFailed">
    Processing error user (email). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.deleteCodeFailed">
    Delete code failed (deleteCodeFailed). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.userIdCodeMismatch">
    JWT messing (userIdCodeMismatch). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.updatePasswordFailed">
    Problem updating password (updatePasswordFailed). Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.changePasswordTokenExpired">
    Token expired. Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.changePasswordTokenInvalid">
    Token invalid. Please contact support for assistance.
    </div>
    <div v-if="this.errors && this.errors.changePasswordTokenMissing">
    Token missing. Please contact support for assistance.
    </div>
  </div>
</template>

<script>

export default {
  name: 'ForgotPasswordError',
  computed: {
      errors() {
          return this.$route.params;
      }
  }
}
</script>
