<template>
  <div>
    <p>404</p>
  </div>
</template>

<script>
export default {
  name: 'Http404'
}
</script>
