<template>
    <header>
      <div class="header-inner">
        <div class="logo-block">
          <a href="https://polygraph.net"><img src="/img/Polygraph-logo.svg" alt="Polygraph"></a>
        </div>
      </div>
    </header>
</template>

<script>

export default {
  name: 'HeaderAuth',
}
</script>
