<template>
    <div class="sign-up-wrap">
      <HeaderAuth />
      <div class="sign-up centered">
        <div class="form-block">
          <div class="form-block-inner">
            <h1 class="ttl center-text">Create An Account</h1>
            <p class="error-large" v-if="error">{{ error }}</p>
            <div class="package">
                <p v-if="clicks != 500">You are signing up to Polygraph's click fraud detection, blocking and analytics service for <span class="highlight">{{ clicks }} ad clicks per month</span> at <span class="highlight">USD {{ price }} per month</span>. You can cancel your subscription at any time.</p>
                <p v-else>You are signing up to Polygraph's click fraud detection, blocking and analytics service for <span class="highlight">{{ clicks }} ad clicks per month</span> (free account).</p>
            </div>
            <form @submit="processRegistration">
              <div class="form-group">
                <label for="full-name" class="raz">
                  <input v-model="form.fullName" type="text" maxlength="50" id="full-name" placeholder=" " />
                  <span>Full Name</span>
                </label>
                <p class="error" v-if="formErrors.fullNameEmpty">Full Name is required.</p>
                <p class="error" v-if="formErrors.fullNameTooLong">Full Name must be shorter than 50 characters.</p>
                <p class="error" v-if="formErrors.fullNameInvalid">Full Name cannot contain more than four words.</p>
              </div>
              <div class="form-group">
                <label for="email" class="raz">
                  <input v-model="form.email" type="text" maxlength="50" id="email" placeholder=" " />
                  <span>E-mail Address</span>
                </label>
                <p class="error" v-if="formErrors.emailEmpty">E-mail is required.</p>
                <p class="error" v-if="formErrors.emailInvalid">Invalid e-mail address.</p>
                <p class="error" v-if="formErrors.emailTooLong">E-mail must be shorter than 50 characters.</p>
              </div>
              <div class="form-group">
                <label for="company" class="raz">
                  <input v-model="form.company" type="text" maxlength="50" id="company" placeholder=" " />
                  <span>Company Name</span>
                </label>
                <p class="error" v-if="formErrors.companyTooLong">Company must be shorter than 50 characters.</p>
              </div>
              <div class="form-group">
                <label for="telephone" class="raz">
                  <input v-model="form.telephone" type="text" maxlength="50" id="telephone" placeholder=" " />
                  <span>Telephone Number</span>
                </label>
                <p class="error" v-if="formErrors.telephoneTooLong">Telephone must be shorter than 50 characters.</p>
              </div>
              <div class="form-group">
                <label for="password" class="raz">
                  <input v-model="form.password" type="password" maxlength="50" id="password" placeholder=" " />
                  <i class="far fa-eye-slash" id="togglePassword"></i>
                  <span>Password</span>
                </label>
                <p class="error" v-if="formErrors.passwordEmpty">Password cannot be blank.</p>
                <p class="error" v-if="formErrors.passwordTooLong">Password must be shorter than 50 characters.</p>
              </div>
              <div class="form-group">
                <input v-model="form.terms" type="checkbox" id="terms-checkbox" name="terms-checkbox" value="">
                <label for="terms-checkbox" class="checkbox-label"></label>
                <p>I accept the <a href="https://polygraph.net/terms-conditions.html">Terms &amp; Conditions</a> and <a href="https://polygraph.net/privacy-policy.html">Privacy Policy</a>.</p>
                <p class="error" v-if="formErrors.terms">You must accept the Terms &amp; Conditions and Privacy Policy.</p>
              </div>
              <div class="form-group">
                <input type="submit" :value="create" :disabled="sending">
              </div>
            </form>
            <div class="have-acc"><span>Already have an account? <router-link to="/login">Log in</router-link></span></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
</template>

<script>

import Footer from '@/components/sitewide/Footer.vue';
import HeaderAuth from '@/components/sitewide/HeaderAuth.vue';

export default {
    name: 'Registration',
    components: {
        Footer,
        HeaderAuth,
    },
    data() {
		return {
            form: {
                fullName: '',
                company: '',
                telephone: '',
                email: '',
                password: '',
                terms: false
            },
            formErrors: {
                fullNameEmpty: false,
                fullNameTooLong: false,
                fullNameInvalid: false,
                companyTooLong: false,
                telephoneTooLong: false,
                emailEmpty: false,
                emailInvalid: false,
                emailTooLong: false,
                passwordEmpty: false,
                passwordTooLong: false,
                terms: false
            },
            sending: false,
            error: null,
            errors: null,
            clicks: 500
        }
    },
    created() {
        // get "?clicks=500" from URL
        const urlParams = new URLSearchParams(window.location.search);
        try {
            let clicks = Number(urlParams.get('clicks')).toLocaleString('en-US');

            if ((clicks != "500") && (clicks != "10,000") && (clicks != "25,000") &&
                (clicks != "50,000") && (clicks != "75,000") && (clicks != "100,000") &&
                (clicks != "200,000") && (clicks != "500,000")) {
                clicks = "25,000";
            }

            this.clicks = clicks;

        } catch(error) {
            console.log(error);
            this.cicks = 500;
        }
    },
    computed: {
        price() {
            if (this.clicks == "25,000") {
                return "$450";
            } else if (this.clicks == "50,000") {
                return "$800";
            } else if (this.clicks == "100,000") {
                return "$1500";
            } else if (this.clicks == "200,000") {
                return "$2500";
            } else if (this.clicks == "500,000") {
                return "$4500";
            } else {
                return "0";
            }
        },
        create() {
            if (this.sending == true) {
                return "Creating...";
            } else {
                return "Create";
            }
        },
        year() {
            return new Date().getFullYear();
        }
    },
    methods: {
        async processRegistration(evt) {

            evt.preventDefault();

            this.formErrors = this.validateForm(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) return;

            this.sending = true;

            try {
                await this.$http.post('/authentication/register',
                    {
                        fullName: this.form.fullName,
                        company: this.form.company,
                        telephone: this.form.telephone,
                        email: this.form.email,
                        password: this.form.password,
                        terms: this.form.terms,
                        language: this.getLanguage(),
                        timezone: this.getTimezone(),
                        clicks: this.clicks
                    }
                );
                this.$router.push({
                    name: 'RegistrationPending'
                })
            } catch(error) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                if (error.response.data.error) {
                    this.error = error.response.data.error;
                } else {
                    this.errors = error.response.data.errors;
                }
            } finally {
                this.sending = false;
            }
        },
        getTimezone() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        },
        getLanguage() {
            return this.$store.getters.getLanguage;
        },
        validateForm(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.fullName)
              errors.fullNameEmpty = true;

            if (form.fullName.length > 50)
              errors.fullNameTooLong = true;

            if (form.fullName.split(" ").length-1 > 3)
              errors.fullNameInvalid = true;

            if (form.company.length > 50)
              errors.companyTooLong = true;

            if (form.telephone.length > 50)
              errors.telephoneTooLong = true;

            if (!form.email)
              errors.emailEmpty = true;

            if (form.email.length > 50)
              errors.emailTooLong = true;

            if (form.email && !(/^\S+@\S+\.\S+$/.test(form.email)))
              errors.emailInvalid = true;

            if (!form.password)
              errors.passwordEmpty = true;

            if (form.password.length > 50)
              errors.passwordTooLong = true;

            if (!form.terms || (form.terms != true && form.terms != false))
              errors.terms = true;

            return errors;
        }
    },
    watch: {
        'form.fullName'() {
            if (this.formErrors.fullNameEmpty || this.formErrors.fullNameTooLong || this.formErrors.fullNameInvalid) {
              this.formErrors.fullNameEmpty = false;
              this.formErrors.fullNameTooLong = false;
              this.formErrors.fullNameInvalid = false;
            }
        },
        'form.company'() {
            if (this.formErrors.companyTooLong)
              this.formErrors.companyTooLong = false;
        },
        'form.telephone'() {
            if (this.formErrors.telephoneTooLong)
              this.formErrors.telephoneTooLong = false;
        },
        'form.email'() {
            if (this.formErrors.emailEmpty || this.formErrors.emailInvalid || this.formErrors.emailTooLong) {
              this.formErrors.emailEmpty = false;
              this.formErrors.emailInvalid = false;
              this.formErrors.emailTooLong = false;
            }
        },
        'form.password'() {
            if (this.formErrors.passwordEmpty || this.formErrors.passwordTooLong) {
              this.formErrors.passwordEmpty = false;
              this.formErrors.passwordTooLong = false;
            }
        },
        'form.terms'() {
            if (this.formErrors.terms) {
              this.formErrors.terms = false;
            }
        }
    },
    mounted() {
        const togglePassword = document.querySelector('#togglePassword');
        const password = document.querySelector('#password');
        togglePassword.addEventListener('click', function() {
            // toggle the type attribute
            let type = null;
            if (password.getAttribute('type') === 'password') {
                type = 'text';
                this.classList.remove('fa-eye-slash');
                this.classList.add('fa-eye');
            } else {
                type = 'password';
                this.classList.remove('fa-eye');
                this.classList.add('fa-eye-slash');
            }
            password.setAttribute('type', type);
        });
    }
}
</script>

<style>
    .error, .error-large {
        margin: 2px 0 0 0 !important;
        color: #FF7F7F !important;
    }
    .error-large {
        font-size: large;
        text-align: center;
    }
    .package {
        margin-bottom: 35px;
        color: #B2B5BC;
        line-height: 22px;
    }
    .highlight {
        font-weight: bold;
    }

</style>
