<template>
  <div>
      Card
      <card-form v-if="this.client.key" @result="processResult" :client="client" />
  </div>
</template>

<script>
import CardForm from '@/components/billing/CardForm.vue'

export default {
    name: 'Card',
    data() {
        return {
            client: {
                email: null,
                key: null,
                secret: null,
                price: null,
                product: null
            }
        }
    },
    components: {
        CardForm
    },
    created() {
        if (!Object.keys(this.$route.params).length) {
            return this.$router.push({
                name: 'SelectProduct',
            });
        }

        this.client.email = this.$route.params.email;
        this.client.key = this.$route.params.key;
        this.client.secret = this.$route.params.secret;
        this.client.price = this.$route.params.price;
        this.client.product = this.$route.params.product;
        this.client.customer = this.$route.params.customer;
        this.client.subscription = this.$route.params.subscription;
    },
    methods: {

        async processResult(evt) {

            try {
                await this.$http.post('/billing/subscription/card', {
                    paymentIntentId: evt.paymentIntent.id,
                    paymentMethod: evt.paymentIntent.payment_method,
                    price: this.client.price,
                    product: this.client.product,
                    customer: this.client.customer,
                    subscription: this.client.subscription
                });

                return this.$router.push({
                    name: 'Dashboard',
                });

            } catch(error) {
                this.error = error.response.data.error;
            }

        }
    }
}
</script>
