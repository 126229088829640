<template>
  <div>
      {{ website }}
      <div v-if="results.length">
        <div>
          <a v-if="active" :href="'/dashboard/website/' + website + '/deactivate'" @click="deactivate">Deactivate</a>
          <a v-if="!active" :href="'/dashboard/website/' + website + '/activate'" @click="activate">Activate</a> |
          <a :href="'/dashboard/website/' + website + '/delete'" @click="confirmDelete">Delete</a>
          <modal v-if="showModal" @close="showModal = false" @confirm="doDelete" :website="website" />
        </div>
        <div>
          <form @submit="getFraud">
            Source:
            <select id="source" v-model="selected.source" @change="onUpdate">
              <option v-for="(item, index) in this.sources" :key="index">{{ item }}</option>
            </select>
            <br />
            Campaign:
            <select id="campaign" v-model="selected.campaign">
              <option v-for="(item, index) in this.campaigns" :key="index">{{ item }}</option>
            </select>
            <br />
            Period:
            <select id="date" v-model="selected.date">
                <option value="All">Past six months</option>
                <option value="0">This month so far</option>
                <option value="1">{{ months[(new Date).getMonth() - 1 ]}}</option>
                <option value="2">{{ months[(new Date).getMonth() - 2 ]}}</option>
                <option value="3">{{ months[(new Date).getMonth() - 3 ]}}</option>
                <option value="4">{{ months[(new Date).getMonth() - 4 ]}}</option>
                <option value="5">{{ months[(new Date).getMonth() - 5 ]}}</option>
            </select>
            <br />
            <button>Filter</button>
          </form>
        </div>
          <!--p v-if="campaigns.length">Source:
              <select v-model="selectedSource" @change="selectSource">
                  <option value="all">All</option>
                  <option v-for="source in sources" :key="source.id">
                      {{ source.utm_source }}
                  </option>
              </select>
          <br />
          <router-link :to="{ name: 'Sources', params: { website: website } }">Remove source from list</router-link>
          </p>
          <p v-if="campaigns.length">Filter by campaign:
              <select v-model="selectedCampaign" @change="selectCampaign">
                  <option value="all">All</option>
                  <option v-for="campaign in campaigns" :key="campaign.id">
                      {{ campaign.utm_campaign }}
                  </option>
              </select>
          <br />
          <router-link :to="{ name: 'Campaigns', params: { website: website } }">Remove campaigns from list</router-link>
          </p>
          <p v-if="campaigns.length">Date range:
              <select v-model="selectedDate">
                  <option value="all">Past six months</option>
                  <option value="0">This month so far</option>
                  <option value="1">{{ months[(new Date).getMonth() - 1 ]}}</option>
                  <option value="2">{{ months[(new Date).getMonth() - 2 ]}}</option>
                  <option value="3">{{ months[(new Date).getMonth() - 3 ]}}</option>
                  <option value="4">{{ months[(new Date).getMonth() - 4 ]}}</option>
                  <option value="5">{{ months[(new Date).getMonth() - 5 ]}}</option>
              </select>
          <br />
          <router-link :to="{ name: 'Sources', params: { website: website } }">Remove source from list</router-link>
          </p>
          <h3 v-if="ready">20 most recent fraud</h3-->
        <div>
          <table class="centre">
              <tr>
                  <th>Date</th>
                  <th>IP</th>
                  <th>Source</th>
                  <th>Campaign</th>
                  <th>Term</th>
                  <th>Reason</th>
              </tr>
              <tr v-if="!frauds.length">
                  <td colspan="6" style="text-align:center">No fraud found.</td>
              </tr>
              <tr v-for="fraud in frauds" :key="fraud.id">
                  <td>{{ cleanDate(fraud.visited_at) }}</td>
                  <td>{{ fraud.ip }}</td>
                  <td>{{ fraud.utm_source }} </td>
                  <td>{{ fraud.utm_campaign }} </td>
                  <td>{{ fraud.utm_term }} </td>
                  <td>{{ fraud.reason }}</td>
              </tr>
          </table>
        </div>
      </div>
      <div v-if="!results.length && this.ready">
          To start monitoring this website, please add the following line of HTML to the &lt;head&gt; section
          of your website's landing page. It is important this is added to the page the visitor will land on
          immediately after clicking your ad. We can't monitor your ads for fraud until you do this. Your adverts
          must use at least one UTM value.

          &lt;script src="https://cdn.polygraph.net/pg.js"&gt;&lt;/script&gt;

          Traffic information will appear here roughly ten minutes after we have received the first traffic from
          your website.
        </div>
  </div>
</template>

<script>
import Modal from '@/components/dashboard/Modal.vue'

export default {
    name: 'Website',
    components: {
        Modal,
    },
    data() {
        return {
          selected: {
            source: 'All',
            campaign: 'All',
            date: "All"
          },
            ready: false,
            //campaigns: [],
            //sources: [],
            frauds: [],
            active: null,
            selectedCampaign: 'all',
            selectedSource: 'all',
            selectedDate: 'all',
            months: ["January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November", "December"],
            error: null,
            showModal: false,
            results: []
        }
    },
    props: ['website'],
    async created() {
        // make sure he is authorised to view this website
        try {
            let response = await this.$http.get('/dashboard/website/' + this.website + '/verify');
            this.active = response.data.result.active;
        } catch(error) {
            try {
                await this.$store.dispatch('logout');
                this.$router.push({ name: 'Login'});
            } catch(error) {
                // do nothing
            }
        }

        // get its campaigns
        // if no campaigns, show the setup message
        try {
            let response = await this.$http.get('/dashboard/website/' + this.website + '/campaigns');
            this.results = response.data.result;
        } catch(error) {
            this.error = error;
        }
/*
        // get its sources
        // if no sources, show the setup message
        try {
            let response = await this.$http.get('/dashboard/website/' + this.website + '/sources');
            this.sources = response.data.result;
        } catch(error) {
            this.error = error;
        }
*/
        this.getFraud();

        this.ready = true;
    },
    methods: {
      async getFraud(evt) {

        if (evt) evt.preventDefault();

        try {
            let response = await this.$http.post('/dashboard/website/' + this.website + '/fraud', {
              source: this.selected.source,
              campaign: this.selected.campaign,
              date: this.selected.date
            });

            this.frauds = response.data.result;

        } catch(error) {
            this.error = error;
        }

        this.ready = true;

      },
        async activate(evt) {
            evt.preventDefault();

            try {
                await this.$http.get('/dashboard/website/' + this.website + '/activate');

                return this.$router.push({
                    name: 'Dashboard',
                });

            } catch(error) {
                this.error = error;
            }
        },
        async deactivate(evt) {
            evt.preventDefault();

            try {
                await this.$http.get('/dashboard/website/' + this.website + '/deactivate');

                return this.$router.push({
                    name: 'Dashboard',
                });

            } catch(error) {
                this.error = error;
            }
        },
        confirmDelete(evt) {

            evt.preventDefault();

            this.showModal = true;
        },
        async doDelete() {

            try {
                await this.$http.get('/dashboard/website/' + this.website + '/delete');

                return this.$router.push({
                    name: 'Dashboard',
                });

            } catch(error) {
                this.error = error;
            }

        },
/*
        async selectCampaign() {

          this.selectedSource = 'all';

          if (this.selectedCampaign == 'all') {
            this.allFraud();
          } else {

            try {
                let response = await this.$http.get('/dashboard/website/' + this.website + '/campaign/' + this.selectedCampaign + '/fraud');
                this.frauds = response.data.result;
            } catch(error) {
                this.error = error;
            }
          }

        },

        async selectSource() {

          this.selectedCampaign = 'all';

          if (this.selectedSource == 'all') {
            this.allFraud();
          } else {

            try {
                let response = await this.$http.get('/dashboard/website/' + this.website + '/source/' + this.selectedSource + '/fraud');
                this.frauds = response.data.result;
            } catch(error) {
                this.error = error;
            }
          }

        },

        async allFraud() {
          // get the most recent fraud
          // displayed when the page first loads
          try {
              let response = await this.$http.get('/dashboard/website/' + this.website + '/fraud');
              this.frauds = response.data.result;
          } catch(error) {
              this.error = error;
          }
        },

        async filtered() {

          try {
              let response = await this.$http.post('/dashboard/website/' + this.website + '/fraud', {
                source: this.selectedSource,
                campaign: this.selectedCampaign,
                date: this.selectedDate
              });
              this.frauds = response.data.result;
          } catch(error) {
              this.error = error;
          }

        },
*/
        cleanDate(visited) {
          return visited.replace(/T/,' ').replace(/.000Z/,'');
        },

        onUpdate() {
          if (!this.campaigns.includes(this.selected.campaign)) {
            this.selected.campaign = 'All'
          }
        }

    },
    computed: {
      sources() {
        var result = []
        this.results.forEach(ele => {
          result.push(ele.source)
        })
        result = [...new Set(result)]
        result.sort()
        result.unshift('All')
        return result
      },
      campaigns() {
        var result = []
        this.results.forEach(ele => {
          if (this.selected.source == 'All' || ele.source === this.selected.source) {
            ele.campaigns.forEach(c => {
              result.push(c.campaign)
            })
          }
        })
        result = [...new Set(result)]
        result.sort()
        result.unshift('All')
        return result
      }
    },

}
</script>
