<template>
    <div>
        <router-link :to="{ name: 'Logout' }"><img src="/img/dashboard/logout_icon_menu.svg" alt="icon">Log out</router-link>
    </div>
</template>

<script>
export default {
  name: 'Logout',
}
</script>
