import Axios from 'axios';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/plugins/i18n';
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

// auto login if token is saved
const token = localStorage.getItem('token');

Vue.prototype.$http = Axios;
if (token) Vue.prototype.$http.defaults.headers.common['Authorization'] = token;

Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.use(VueGtag, {
  config: { id: "UA-220732947-1" },
  includes: [
    { id: 'AW-325180611' },
  ]
});

new Vue({
    Axios,
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
