<template>
  <div class="py-8 p-4 min-h-screen w-screen flex items-center justify-center text-white bg-conic-dark">
      <div class="flex flex-col prose prose text-center max-w-sm">
          <img src="/assets/brand/polygraph-logo-white.svg" alt="Polygraph">
          <h2>Reset Password</h2>
          <p>One moment please...</p>
      </div>
  </div>
</template>

<script>
export default {
    name: 'ForgotPasswordVerify',
    props: ['code'],
    async created() {

        if (!/^[a-z0-9]+$/i.test(this.code) || this.code.length !== 25) {

            return this.$router.push({
                name: 'ForgotPasswordError',
                params: { codeInvalid: true }
            });

        } else {

            try {
                const response = await this.$http.post('/authentication/password/confirm',
                    {
                        code: this.code,
                    }
                );

                return this.$router.push({
                    name: 'ForgotPasswordChange',
                    params: {
                        code: this.code,
                        changePasswordToken: response.data.result
                    }
                });
            } catch(error) {

                this.$router.push({
                    name: 'ForgotPasswordError',
                    params: error.response.data.error
                });
            }
        }
    },
}
</script>
